import axios from 'axios';

const createBackendServer = () => {
  const api = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/`, // Using URL from ENV Variables
    withCredentials: false,
    headers: {
      Accept: 'application/json',
    },
    timeout: 60 * 1000, // Timeout of 1 minute
  });

  const userToken = localStorage.getItem('authToken');
  // Request interceptor
  api.interceptors.request.use(
    (config) => {
      const userToken = localStorage.getItem('authToken');
      const userId = localStorage.getItem('userId');
      if (userToken) {
        config.headers.Authorization = `Bearer ${userToken}`;
      }
      if (userId) {
        config.headers['X-User-Id'] = userId; // Use a custom header for user ID
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  // Response interceptor
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      const message = error?.response?.data?.message;
      error.message = message ?? error.message;
      return Promise.reject(error);
    }
  );

  const check = () => api.get('/');
  // Login function
  const login = (body) => api.post('login', body);

  // get student data
  const getStudentData = () => api.get('portfolio/students');

  // get student data
  const getStudentsAll = () => api.get('students-get-all');

  // get student data
  const getStudents = (classroomId) => api.get(`portfolio/students?classroomId=${classroomId}`);

  // get student data
  const getStudentProfile = (studentId) => api.get(`portfolio/students/${studentId}`);

  // get inactive students
  const getInactiveStudent = () => api.get('students-get-inactive');

  // get active students
  const getActiveStudent = () => api.get('students-get-active');

  // Create Student
  const createStudent = (studentData) => api.post('children', studentData); // Assuming POST /children is for creating a student

  // Create Parent
  const createParent = (parentData) => api.post('parents', parentData); // Assuming POST /parents is for creating a parent

  // get parent data
  const getParentData = () => api.get('parents/get-all');

  // get parent data
  const getParentById = (id) => api.get(`parents/${id}`);

  // get staff data
  const getStaff = () => api.get('staff');

  // get active student data
  const getActiveUsers = () => api.get('activeusers');

  // get inactive student data
  const getInactiveUsers = () => api.get('inactiveusers');

  // get getGraduated student data
  const getGraduated = () => api.get('students-get-graduated');

  // get scheduled student data
  const getScheduled = () => api.get('students-get-scheduled');

  // get waitlist student data
  const getWaitlist = () => api.get('students-get-waitlist');

  // get visited student data
  const getVisited = () => api.get('students-get-visited');

  // get interested student data
  const getInterested = () => api.get('students-get-interested');

  // get touring student data
  const getTouring = () => api.get('students-get-touring');

  // Add this function to your API service

  // Update student status
  const updateStudentStatus = (studentId, newStatus) => {
    // Ensure status is valid before sending the request
    const validStatuses = [
      'Graduated',
      'waiting',
      'Visited',
      'Touring',
      'Scheduled',
      'Interested',
      'Inactive',
      'Active',
    ];
    if (!validStatuses.includes(newStatus)) {
      return Promise.reject(new Error('Invalid status provided'));
    }

    // Make the PUT request to update the student's status
    return api.put(`/students-update-status`, { studentId, newStatus });
  };

  // get student payment data
  const getStudentPaymentData = () => api.get('billingrequests/all');

  // billing request
  const billingRequest = (body) => api.post('billingrequests', body);

  const getbillingRequest = () => api.get('billingrequests');

  const getBillingSummary = () => api.get('billingrequests/summary');

  // Classroom services
  const classroomService = () => api.get('classrooms');

  // Classroom services
  const incidents = (selectedClassroom) => api.get(`portfolio/incidents?classroomId=${selectedClassroom}`);

  // lesson

  // eslint-disable-next-line camelcase
  const fetchLessons = (start_date, end_date) => api.get(`lessons/details`, start_date, end_date);

  // Classroom services
  // eslint-disable-next-line camelcase
  const fetchLessonDetails = (classroom_id, date) => api.get(`lessons/details`, classroom_id, date);

  // send message services
  const sendMessage = (body) => api.post('messages/sent', body);

  const sendMassMessage = (body) => api.post('messages/sent/mass', body);

  const getMessageToday = () => api.get('messages/sent/today');

  const getUsersData = () => api.get('get-users');

  const sendNote = (body) => api.post('messages/sent', body); // Assuming the endpoint is 'messages/sent'

  // report
  // GET report for a student by studentId
  const getDailyReport = (studentId) => api.get(`dailyreports/${studentId}`);

  // GET report for a student by studentId
  const getDailyReportByDate = (studentId, formattedDate) => api.get(`dailyreports/${studentId}/${formattedDate}`);

  // POST a new daily report
  const saveReport = (reportData) => api.post('dailyreports', reportData);

  // PUT to update a daily report by reportId
  const updateDailyReport = (reportId, reportData) => api.put(`dailyreports/${reportId}`, reportData);

  const sendNotification = (body) => api.post('notification/post', body);

  return {
    check,
    login,
    getStudentData,
    getStudents,
    getStudentProfile,
    getParentData,
    getStudentPaymentData,
    billingRequest,
    getbillingRequest,
    classroomService,
    sendMessage,
    sendMassMessage,
    getMessageToday,
    incidents,
    fetchLessonDetails,
    fetchLessons,
    getStaff,
    getUsersData,
    getBillingSummary,
    getActiveUsers,
    getInactiveUsers,
    getDailyReport,
    saveReport,
    updateDailyReport,
    createStudent,
    createParent,
    getStudentsAll,
    getParentById,
    getInactiveStudent,
    getActiveStudent,
    sendNote,
    getGraduated,
    getScheduled,
    getVisited,
    getTouring,
    getInterested,
    getWaitlist,
    updateStudentStatus,
    getDailyReportByDate,
  };
};

// Create the API instance
const apis = createBackendServer(); // No need for baseURL here
export default apis;
