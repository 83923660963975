import { useEffect, useState } from 'react';
// @mui
import {
  Box,
  Typography,
  Tabs,
  Tab,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Snackbar,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
// eslint-disable-next-line import/no-unresolved
import apis from 'src/services/api'; // API service for making requests

export default function BillingManagement() {
  const [activeTab, setActiveTab] = useState(0);
  const [parentData, setParentData] = useState([]); // Renamed from studentData to parentData
  const [messageType, setMessageType] = useState(''); // Message type (text, email, note)
  const [recipient, setRecipient] = useState(''); // Selected recipient (parent)
  const [message, setMessage] = useState(''); // Message content
  const [loading, setLoading] = useState(false); // Loading state for fetching data
  const [phoneNumber, setPhoneNumber] = useState(''); // State for storing phone number input
  const [email, setEmail] = useState(''); // State for storing email input
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  // Fetch parent data from API
  const getParentData = async () => {
    setLoading(true); // Show loader when making API call
    try {
      const response = await apis.getParentData(); // Fetch parent data
      console.log('Parent Data:', response?.data); // Inspect the API response
      setParentData(Array.isArray(response?.data?.parents) ? response?.data?.parents : []); // Access the parents array properly
    } catch (error) {
      console.error(error?.response?.data?.message);
    } finally {
      setLoading(false); // Hide loader after data is fetched
    }
  };

  // Fetch parent data when component mounts or when activeTab changes
  useEffect(() => {if (activeTab === 0) {
    getParentData(); // Fetch parent data only when activeTab is 1
  }}, [activeTab]);

  // Handle tab change (Parent Messaging or Mass Messaging)
  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  // Handle message form submission (sending message)
  const handleMessageSubmit = async (event) => {
    event.preventDefault(); // Prevent form submission

    try {
      // Prepare the body data based on message type
      const body = {
        recipientId: recipient, // Recipient ID
        messageType, // 'text', 'email', or 'note'
        content: message, // Message content
        senderID: localStorage.getItem('userId')
        // phoneNumber: messageType === 'text' ? phoneNumber : undefined, // Include phone number for 'text' only
        // email: messageType === 'email' ? email : undefined, // Include email for 'email' only
      };

      // console.log(body);

      // Make the API call to send the message or show notification on parents portal
      const response = await apis.sendMessage(body);

      // console.log(response);

      // Show success snackbar
      setSnackbar({
        open: true,
        message: response?.data?.message || " ",
        severity: 'success',
      });

      // Clear form data after successful submission
      setMessageType('');
      setRecipient('');
      setMessage('');
      setPhoneNumber('');
      setEmail('');
    } catch (error) {
      console.error(error);

      // Show error snackbar
      setSnackbar({
        open: true,
        message: `Error sending message: ${error.response.data.details}`,
        severity: 'error',
      });
    }
  };


  /* Mass Messaging */


  // Handle Mass message form submission (sending message)
  const handleMassMessageSubmit = async (event) => {
    event.preventDefault(); // Prevent form submission

    try {
      // Prepare the body data based on message type
      const body = {
        // recipientId: recipient, // Recipient ID, no need here, get directly from DB
        messageType, // 'text', 'email', or 'note'
        content: message, // Message content
        senderID: localStorage.getItem('userId')
        // phoneNumber: messageType === 'text' ? phoneNumber : undefined, // Include phone number for 'text' only
        // email: messageType === 'email' ? email : undefined, // Include email for 'email' only
      };

      // Make the API call to send the message or show notification on parents portal
      const response = await apis.sendMassMessage(body);

      // const response = await apis.getMessageToday();     //just for checking

      // const response = await apis.check();               //just for checking

      // console.log(response);

      // Show success snackbar
      setSnackbar({
        open: true,
        message: response?.data?.message || " ",
        severity: 'success',
      });

      // Clear form data after successful submission
      // setMessageType('');
      // setRecipient('');
      // setMessage('');
      // setPhoneNumber('');
      // setEmail('');
      
    } catch (error) {

      // console.error(error);

      // Show error snackbar
      setSnackbar({
        open: true,
        message: `Error sending message: ${error.response.data.details}`,
        severity: 'error',
      });
    }
  };




  // Handle snackbar close event
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h3" paragraph>
        Parent Messaging
      </Typography>

      {/* Tabs for switching between Parent Messaging and Mass Messaging */}
      <Tabs
        value={activeTab}
        direction="column"
        onChange={handleTabChange}
        sx={{ mb: 2, display: 'flex', flexDirection: 'row' }}
      >
        <Tab label="Parent Messaging" />
        <Tab label="Mass Messaging" />
      </Tabs>

      {/* Loader */}
      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
          <CircularProgress />
        </Box>
      )}

      {/* Parent Messaging Form */}
      {activeTab === 0 && (
        <form onSubmit={handleMessageSubmit}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Message Type</InputLabel>
            <Select value={messageType} onChange={(e) => setMessageType(e.target.value)}>
              <MenuItem value="text">Text</MenuItem>
              <MenuItem value="email">Email</MenuItem>
              <MenuItem value="note">Note</MenuItem>
            </Select>
          </FormControl>

          {/* Recipient Selection */}
          <TextField
            select
            fullWidth
            label="Recipient"
            value={recipient}
            onChange={(e) => {
              setRecipient(e.target.value);
            }}
            sx={{ mb: 2 }}
            SelectProps={{
              native: true,
            }}
          >
            <option value="">Select a recipient</option>
            {parentData?.map((parent) => (
              <option key={parent?.id} value={parent?.id}>
                {`${parent?.firstName} ${parent?.lastName}`}
              </option>
            ))}
          </TextField>

          {/* Message Input */}
          <TextField
            fullWidth
            label="Message"
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            sx={{ mb: 2 }}
          />

          {/* Show phone number input if 'Text' is selected */}
          {messageType === 'text_old' && (
            <TextField
              fullWidth
              label="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              sx={{ mb: 2 }}
            />
          )}

          {/* Show email input if 'Email' is selected */}
          {messageType === 'email_old' && (
            <TextField
              fullWidth
              label="Recipient Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ mb: 2 }}
            />
          )}

          {/* Submit Button */}
          <Button type="submit" variant="contained" color="primary">
            Send Message
          </Button>
        </form>
      )}

      {/* Mass Messaging Form */}
      {activeTab === 1 && (
        <form onSubmit={handleMassMessageSubmit}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Message Type</InputLabel>
            <Select value={messageType} onChange={(e) => setMessageType(e.target.value)}>
              <MenuItem value="text">Text</MenuItem>
              <MenuItem value="email">Email</MenuItem>
              <MenuItem value="note">Note</MenuItem>
            </Select>
          </FormControl>

          {/* Message Input */}
          <TextField
            fullWidth
            label="Message"
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            sx={{ mb: 2 }}
          />

          {/* Show phone number input if 'Text' is selected */}
          {messageType === 'text_old' && (
            <TextField
              fullWidth
              label="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              sx={{ mb: 2 }}
            />
          )}

          {/* Show email input if 'Email' is selected */}
          {messageType === 'email_old' && (
            <TextField
              fullWidth
              label="Recipient Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ mb: 2 }}
            />
          )}

          {/* Submit Button */}
          <Button type="submit" variant="contained" color="primary">
            Send Message
          </Button>
        </form>
      )}

      {/* Snackbar for notifications */}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <MuiAlert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
}
