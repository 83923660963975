/* eslint-disable no-undef */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { debounce } from 'lodash'; // If lodash is available, otherwise, we can implement custom debounce
import { Form, FormikProvider, useFormik } from 'formik';
// @mui
import {
  Stack,
  Divider,
  MenuItem,
  IconButton,
  Box,
  Typography,
  Button,
  Chip,
  Tabs,
  Tab,
  TextField,
  InputAdornment,
  CircularProgress, // Import CircularProgress for loader
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Snackbar,
  Alert,
  Paper,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Pagination,
} from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import apis from 'src/services/api';
// eslint-disable-next-line import/no-unresolved
import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell, Legend } from 'recharts';
import Iconify from '../components/Iconify';
import MenuPopover from '../components/MenuPopover';
// eslint-disable-next-line import/order, import/no-unresolved
// eslint-disable-next-line import/order
import { useSnackbar, SnackbarProvider } from 'notistack';

const size = {
  width: 400,
  height: 200,
};

// Helper function to format date
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
};

// Helper function to determine payment status
const getPaymentStatus = (requestDate) => {
  const currentDate = new Date();
  const due = new Date(requestDate);

  if (due < currentDate) {
    return 'Overdue';
  }
  return 'Pending';
};

// Colors for the pie chart
const COLORS = ['#0088FE', '#FFBB28', '#FF4444'];

export default function BillingManagement() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [activeTab, setActiveTab] = useState(0);
  const [studentPaymentData, setStudentPaymentData] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);
  const [inactiveUsers, setInactiveUsers] = useState([]);
  const [userHistory, setUserHistory] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false); // Loader state
  const [selectedStudents, setSelectedStudents] = useState([]); // Track selected students
  const [paymentAmount, setPaymentAmount] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [showPaymentForm, setShowPaymentForm] = useState(false); // Track whether to show the payment form
  const [chartData, setChartData] = useState([]);
  const [error, setError] = useState('');
  const [paidCount, setPaidCount] = useState(0); // Count for paid students
  const [pendingCount, setPendingCount] = useState(0); // Count for pending students
  const [overdueCount, setOverdueCount] = useState(0); // Count for overdue students

  const [activeUsersPage, setActiveUsersPage] = useState(1);
  const [inactiveUsersPage, setInactiveUsersPage] = useState(1);
  const [page, setPage] = useState(1);
  const itemsPerPage = 8; // Adjust the number of items per page as needed

  // Handle page change
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // setSnackbar({
  //   open: true,
  //   message: `Error sending message: ${error.response.data.details}`,
  //   severity: 'error',
  // });

  // Fetch student data
  const getStudentData = async () => {
    setLoading(true); // Show loader when making API call
    try {
      const response = await apis.getStudentData();
      setStudentData(response?.data);
    } catch (error) {
      console.error(error?.response?.data?.message);
    } finally {
      setLoading(false); // Hide loader after data is fetched
    }
  };

  // Fetch student payment data
  // const getPaymentRequests = async () => {
  //   setLoading(true); // Show loader when making API call
  //   try {
  //     const response = await apis.getbillingRequest();
  //     console.log(response?.data?.data);
  //     setStudentPaymentData(response?.data?.data);
  //   } catch (error) {
  //     console.error(error?.response?.data?.message);
  //   } finally {
  //     setLoading(false); // Hide loader after data is fetched
  //   }
  // };

  // Fetch student payment data

  // Fetch payment data
  const getPaymentRequests = async () => {
    setLoading(true);
    try {
      const response = await apis.getbillingRequest();
      setStudentPaymentData(response?.data?.data);
      calculatePaymentSummary(response?.data?.data);
    } catch (error) {
      console.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const getStudentNamebyId = (studentId) => {
    const student = studentData.find((student) => student.id === studentId);

    if (student) {
      return `${student.first_name} ${student.last_name}`;
    }

    return 'Student not found';
  };

  // Filter the studentPaymentData based on the search query for Requested Tab
  const filteredPaymentData = studentPaymentData.filter(
    (payment) =>
      payment.billing_amount.toString().includes(searchQuery) ||
      getStudentNamebyId(payment.student_id).toLowerCase().includes(searchQuery.toLowerCase()) ||
      formatDate(payment.request_date).toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Calculate the summary counts for paid, pending, and overdue payments
  const calculatePaymentSummary = (paymentData) => {
    let paid = 0;
    let pending = 0;
    let overdue = 0;

    paymentData.forEach((payment) => {
      const paymentStatus = getPaymentStatus(payment.request_date);
      if (paymentStatus === 'Paid') {
        paid++;
      } else if (paymentStatus === 'Pending') {
        pending++;
      } else if (paymentStatus === 'Overdue') {
        overdue++;
      }
    });

    setPaidCount(paid);
    setPendingCount(pending);
    setOverdueCount(overdue);

    // Update chartData
    setChartData([
      { name: 'Paid', value: paid },
      { name: 'Pending', value: pending },
      { name: 'Overdue', value: overdue },
    ]);
  };

  // Helper function to determine payment status
  const getPaymentStatus = (requestDate) => {
    const currentDate = new Date();
    const dueDate = new Date(requestDate);

    if (dueDate < currentDate) {
      return 'Overdue';
    }
    return 'Pending';
  };

  // Calculate the percentage of each category (This needs to be done once the counts are available)
  const totalPayments = paidCount + pendingCount + overdueCount;
  const paidPercentage = totalPayments ? ((paidCount / totalPayments) * 100).toFixed(1) : 0;
  const pendingPercentage = totalPayments ? ((pendingCount / totalPayments) * 100).toFixed(1) : 0;
  const overduePercentage = totalPayments ? ((overdueCount / totalPayments) * 100).toFixed(1) : 0;

  // Fetch active users
  // Fetch active users
  // Fetch active users
  const getActiveUsers = async () => {
    setLoading(true);
    try {
      const response = await apis.getActiveStudent(); // Fetch active users from the API

      // Ensure the response data is an array before setting it to state
      if (Array.isArray(response?.data?.students)) {
        setActiveUsers(response?.data?.students);
      } else {
        enqueueSnackbar('Invalid data format for active users', { variant: 'error' });
        setActiveUsers([]); // Fallback to empty array if the response is not as expected
      }
    } catch (error) {
      console.error(error?.response?.data?.message);
      enqueueSnackbar('Failed to fetch active users', { variant: 'error' });
      setActiveUsers([]); // Ensure the state is reset on error
    } finally {
      setLoading(false);
    }
  };

  // Fetch inactive users
  const getInactiveUsers = async () => {
    setLoading(true);
    try {
      const response = await apis.getInactiveStudent(); // Fetch inactive users from the API
      setInactiveUsers(response?.data?.students);
    } catch (error) {
      console.error(error?.response?.data?.message);
      enqueueSnackbar('Failed to fetch inactive users', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  // Toggle student status (Active <-> Inactive)
  const toggleStudentStatus = async (studentId, currentStatus) => {
    setLoading(true);
    const newStatus = currentStatus === 'Active' ? 'Inactive' : 'Active';

    try {
      const response = await apis.updateStudentStatus(studentId, newStatus); // Call API to toggle status
      if (response?.data?.success) {
        enqueueSnackbar(`Student status updated to ${newStatus}`, { variant: 'success' });

        // Update local state to reflect the status change
        if (newStatus === 'Active') {
          // setInactiveUsers(inactiveUsers?.filter((user) => user.id !== studentId));
          setActiveUsers([...activeUsers, response.data.student]); // Assuming response returns updated student
        } else {
          setActiveUsers(activeUsers?.filter((user) => user.id !== studentId));
          setInactiveUsers([...inactiveUsers, response.data.student]);
        }
      } else {
        enqueueSnackbar('Failed to update student status', { variant: 'error' });
      }
      getActiveUsers();
      getInactiveUsers();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Error updating student status', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };
  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Update the filtered lists
  const filteredStudentData = studentData.filter((student) =>
    `${student.first_name} ${student.last_name}`.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredStudentPaymentData = studentPaymentData.filter((payment) =>
    getStudentNamebyId(payment.student_id).toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSelectStudent = (studentId) => {
    // Toggle selection of student
    setSelectedStudents(
      (prevSelected) =>
        prevSelected.includes(studentId)
          ? prevSelected.filter((id) => id !== studentId) // Remove student if already selected
          : [...prevSelected, studentId] // Add student if not selected
    );
  };

  const handleGeneratePaymentRequest = () => {
    // Toggle the show payment form state to display payment details for selected students
    setShowPaymentForm(true);
  };

  // const handleSubmitPaymentRequest = async (event) => {
  //   // Prepare the payment request data for the selected students
  //   const payments = selectedStudents.map((studentId) => ({
  //     studentId,
  //     paymentAmount,
  //     dueDate,
  //     status: 'pending',
  //   }));

  //   console.log('Generated Payments:', payments);
  //   // Example API call for generating the payment request
  //   // apis.generatePaymentRequest(payments);

  //   try {
  //     const response = await apis.billingRequest(payments);
  //   } catch (error) {
  //     console.error(error);

  //     // Show error snackbar
  //     /*  Ghulam Bhai Snack Bar set karden ga idhr
  //      */
  //     setSnackbar({
  //       open: true,
  //       message: `Error sending message: ${error.response.data.details}`,
  //       severity: 'error',
  //     });
  //   }
  // };

  const handleCancelPaymentRequest = () => {
    // Reset selected students, payment amount, and due date, and hide the payment form
    setSelectedStudents([]);
    setPaymentAmount('');
    setDueDate('');
    setShowPaymentForm(false);
  };
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  const handleSubmitPaymentRequest = async (event) => {
    if (!paymentAmount || !dueDate || selectedStudents.length === 0) {
      setSnackbar({
        open: true,
        message: 'Please fill in all fields and select at least one student.',
        severity: 'error',
      });
      return;
    }
    // Set loading state to true when the request starts
    setLoading(true);

    // Generate payment data with the required fields
    const payments = selectedStudents.map((studentId) => {
      const studentName = getStudentNamebyId(studentId);

      // Make sure the due_date is formatted correctly
      const formattedDueDate = new Date(dueDate).toISOString().split('T')[0]; // 'yyyy-MM-dd'

      // Use the user-selected date for request_date
      const formattedRequestDate = new Date(dueDate); // Use requestDate for request_date

      return {
        student_id: studentId,
        student_name: studentName,
        status: 'pending',
        request_date: formattedDueDate, // Use user-selected request date
        billing_amount: parseFloat(paymentAmount), // Ensure it's a number
        due_date: formattedDueDate, // Ensure it's 'yyyy-MM-dd' format
      };
    });

    console.log('Generated Payments:', payments); // Log the data to ensure correctness
    try {
      const response = await apis.billingRequest(payments);
      setSnackbar({
        open: true,
        message: 'Payment requests submitted successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error response:', error.response?.data); // Log the error response body here
      setSnackbar({
        open: true,
        message: `Error sending payment request: ${error.response?.data?.details || error.message}`,
        severity: 'error',
      });
    } finally {
      getPaymentRequests();
      setSelectedStudents([]);
      setPaymentAmount('');
      setDueDate('');
      // setRequestDate(''); // Clear the selected request date
      // fetchData();
      handleCancelPaymentRequest();
    }
  };

  // Filter students based on search query
  const filteredActiveUsers = activeUsers.filter(
    (user) =>
      user.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.last_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredData = filteredStudentPaymentData?.filter((task) =>
    String(task?.student_id)?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Paginate the filtered data
  const startIndex = (page - 1) * itemsPerPage;
  const paginatedData = filteredData?.slice(startIndex, startIndex + itemsPerPage);

  const handleActiveUsersPageChange = (event, newPage) => {
    setActiveUsersPage(newPage);
  };

  const handleInactiveUsersPageChange = (event, newPage) => {
    setInactiveUsersPage(newPage);
  };

  const startActiveIndex = (activeUsersPage - 1) * itemsPerPage;
  const paginatedActiveUsers = activeUsers.slice(startActiveIndex, startActiveIndex + itemsPerPage);

  const startInactiveIndex = (inactiveUsersPage - 1) * itemsPerPage;
  const paginatedInactiveUsers = inactiveUsers.slice(startInactiveIndex, startInactiveIndex + itemsPerPage);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  useEffect(() => {
    if (activeTab === 0) {
      getPaymentRequests();
    } else if (activeTab === 1) {
      getActiveUsers();
    } else if (activeTab === 2) {
      getInactiveUsers();
    }
    getStudentData();
  }, [activeTab]);

  return (
    <>
      <Box sx={{ padding: 3 }}>
        <Typography variant="h3" paragraph>
          Billing Management
        </Typography>

        {/* Tabs for switching between Requested and Active lists */}
        <Tabs
          value={activeTab}
          direction="column"
          onChange={handleTabChange}
          sx={{ mb: 2, display: 'flex', flexDirection: 'row' }}
        >
          <Tab label="Requested" />
          <Tab label="Active" />
          <Tab label="Inactive" />
          <Tab label="Report" />
        </Tabs>

        {/* Loader */}
        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
            <CircularProgress />
          </Box>
        )}

        {/* Requested Students */}
        {activeTab === 0 && !loading && !showPaymentForm && (
          <>
            <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
              <Button variant="contained" onClick={handleGeneratePaymentRequest}>
                Generate Payment Request
              </Button>
            </Stack>
            <TextField
              label="Search Payments"
              value={searchQuery}
              onChange={handleSearchChange}
              fullWidth
              sx={{ mb: 2 }}
            />
            <Stack direction="column" spacing={2}>
              {/* Display paginated filtered payment data */}
              {paginatedData?.map((task) => (
                <Stack
                  direction="row"
                  sx={{
                    px: 2,
                    py: 1,
                    backgroundColor: '#f9f9f9',
                    borderRadius: '14px',
                    mb: 2,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                  key={task?.id}
                >
                  <Box sx={{ width: '15%' }}>
                    <Typography variant="body1" sx={{ color: '#307FE2' }}>
                      ${task?.billing_amount}
                    </Typography>
                  </Box>
                  <Box sx={{ width: '30%' }}>
                    <Typography variant="body1">{getStudentNamebyId(task?.student_id)}</Typography>
                  </Box>
                  <Box sx={{ width: '25%' }}>
                    <Typography variant="body1">{formatDate(task?.request_date)}</Typography>
                  </Box>
                  <Box sx={{ width: '15%' }}>
                    <Chip
                      label={getPaymentStatus(task?.request_date)}
                      color={getPaymentStatus(task?.request_date) === 'Overdue' ? 'error' : 'warning'}
                    />
                  </Box>
                </Stack>
              ))}
            </Stack>

            {/* Pagination Component */}
            <Pagination
              count={Math.ceil(filteredData?.length / itemsPerPage)} // Total pages based on the filtered data
              page={page}
              onChange={handlePageChange}
              color="primary"
              sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
            />
          </>
        )}

        {/* Show payment form when "Generate Payment Request" is clicked */}

        {/* Active Users List */}
        {activeTab === 1 && !loading && (
          <>
            <TextField
              value={searchQuery}
              onChange={handleSearchChange}
              label="Search Active Users"
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
            />

            <Stack direction="column" spacing={2}>
              {paginatedActiveUsers.map((user) => (
                <Stack
                  direction="row"
                  sx={{
                    px: 2,
                    py: 1,
                    backgroundColor: '#f9f9f9',
                    borderRadius: '14px',
                    mb: 2,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                  key={user.id}
                >
                  <Box sx={{ width: '50%' }}>
                    <Typography variant="body1">
                      {user.first_name} {user.last_name}
                    </Typography>
                  </Box>
                  <Box sx={{ width: '25%' }}>
                    <Button variant="outlined" onClick={() => toggleStudentStatus(user.id, user.status)}>
                      Inactivate
                    </Button>
                  </Box>
                </Stack>
              ))}
            </Stack>

            {/* Show Pagination only if necessary */}
            {activeUsers.length > itemsPerPage && (
              <Pagination
                count={Math.ceil(activeUsers.length / itemsPerPage)}
                page={activeUsersPage}
                onChange={handleActiveUsersPageChange}
                color="primary"
                sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
              />
            )}
          </>
        )}

        {/* Inactive Users List */}
        {activeTab === 2 && !loading && (
          <>
            <TextField
              value={searchQuery}
              onChange={handleSearchChange}
              label="Search Inactive Users"
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
            />

            <Stack direction="column" spacing={2}>
              {paginatedInactiveUsers.map((user) => (
                <Stack
                  direction="row"
                  sx={{
                    px: 2,
                    py: 1,
                    backgroundColor: '#f9f9f9',
                    borderRadius: '14px',
                    mb: 2,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                  key={user.id}
                >
                  <Box sx={{ width: '50%' }}>
                    <Typography variant="body1">
                      {user.first_name} {user.last_name}
                    </Typography>
                  </Box>
                  <Box sx={{ width: '25%' }}>
                    <Button variant="outlined" onClick={() => toggleStudentStatus(user.id, user.status)}>
                      Activate
                    </Button>
                  </Box>
                </Stack>
              ))}

              {/* Show Pagination only if necessary */}
              {inactiveUsers.length > itemsPerPage && (
                <Pagination
                  count={Math.ceil(inactiveUsers.length / itemsPerPage)}
                  page={inactiveUsersPage}
                  onChange={handleInactiveUsersPageChange}
                  color="primary"
                  sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
                />
              )}
            </Stack>
          </>
        )}
        {activeTab === 3 && !loading && (
          <>
            {!loading && (
              <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
                <Typography variant="h6" sx={{ flex: 1 }}>
                  Total Paid: {paidCount} ({paidPercentage}%)
                </Typography>
                <Typography variant="h6" sx={{ flex: 1 }}>
                  Total Pending: {pendingCount} ({pendingPercentage}%)
                </Typography>
                <Typography variant="h6" sx={{ flex: 1 }}>
                  Total Overdue: {overdueCount} ({overduePercentage}%)
                </Typography>
              </Stack>
            )}
            {/* Pie Chart */}
            {!loading && chartData.length > 0 && (
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={chartData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    label={({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
                      const total = chartData.reduce((sum, data) => sum + data.value, 0);
                      const percentage = total ? ((value / total) * 100).toFixed(1) : 0;

                      const RADIAN = Math.PI / 180;
                      const radius = outerRadius + (innerRadius - outerRadius) * 0.5;
                      const x = cx + radius * Math.cos(-midAngle * RADIAN);
                      const y = cy + radius * Math.sin(-midAngle * RADIAN);

                      return (
                        <text
                          x={x}
                          y={y}
                          fill="white"
                          textAnchor="middle"
                          dominantBaseline="middle"
                          fontSize="14"
                          fontWeight="bold"
                        >
                          {`${percentage}%`}
                        </text>
                      );
                    }}
                  >
                    {chartData.map((_, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            )}
          </>
        )}
      </Box>
      {showPaymentForm && (
        <Dialog open={showPaymentForm} onClose={handleCancelPaymentRequest} sx={{ width: '100vw' }}>
          <DialogTitle>Generate Payment Request</DialogTitle>
          <DialogContent sx={{ width: '80vw' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={7}>
                <Paper elevation={3} sx={{ p: 2, height: 600, overflowY: 'auto' }}>
                  <TextField
                    label="Search Students"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  {filteredStudentData?.map((task) => (
                    <Stack
                      direction="row"
                      sx={{
                        px: 2,
                        py: 1,
                        backgroundColor: '#f9f9f9',
                        borderRadius: '14px',
                        mb: 2,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                      key={task.id}
                    >
                      <Box sx={{ width: '5%' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedStudents.includes(task.id)}
                              onChange={() => handleSelectStudent(task.id)}
                            />
                          }
                        />
                      </Box>
                      <Box sx={{ width: '65%' }}>
                        <Typography variant="body1">{`${task.first_name} ${task.last_name}`}</Typography>
                      </Box>
                      <Box sx={{ width: '28%' }}>
                        <Typography variant="body1">{task.classroom_name}</Typography>
                      </Box>
                    </Stack>
                  ))}
                </Paper>
              </Grid>
              <Grid item xs={12} md={5}>
                <Paper elevation={3} sx={{ p: 2, height: 400, overflowY: 'auto' }}>
                  <Typography variant="h5" sx={{ mb: 2 }}>
                    Enter Payment Details
                  </Typography>
                  <Stack direction="row" spacing={2} sx={{ my: 3 }}>
                    <TextField
                      label="Payment Amount"
                      type="number"
                      value={paymentAmount}
                      onChange={(e) => setPaymentAmount(e.target.value)}
                      fullWidth
                      sx={{ mb: 2 }}
                    />
                    <TextField
                      label="Due Date"
                      type="date"
                      value={dueDate}
                      onChange={(e) => setDueDate(e.target.value)}
                      fullWidth
                      sx={{ mb: 2 }}
                    />
                  </Stack>

                  <Stack direction="row" spacing={2}>
                    <Button
                      variant="contained"
                      onClick={handleSubmitPaymentRequest}
                      sx={{ mt: 2 }}
                      disabled={loading} // Disable button while loading
                    >
                      {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit Payment Request'}
                    </Button>
                    <Button variant="outlined" onClick={handleCancelPaymentRequest} sx={{ mt: 2 }}>
                      Cancel
                    </Button>
                  </Stack>
                </Paper>
              </Grid>
            </Grid>
          </DialogContent>
          <Snackbar open={snackbar?.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
            <Alert onClose={handleSnackbarClose} severity={snackbar?.severity} sx={{ width: '100%' }}>
              {snackbar?.message}
            </Alert>
          </Snackbar>
        </Dialog>
      )}
    </>
  );
}
