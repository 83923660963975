import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import LogoImg from '../assets/logo.png';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  // const logo = <Box component="img" src="/static/logo.svg" sx={{ width: 40, height: 40, ...sx }} />

  const logo = (
    <Box sx={{ width: 140, height: 20, ...sx }}>
      <Typography
        variant="h4"
        sx={{
          mb: 5, // Margin bottom of 5
          backgroundColor: 'primary.main', // Background color (using theme's primary color)
          color: 'white', // Text color
          padding: '10px 20px', // Padding around the text
          borderRadius: '8px', // Rounded corners
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Light shadow effect
          textShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)', // Text shadow
          fontWeight: 'bold', // Bold text
          textAlign: 'center', // Centered text
          textDecoration: 'none',
        }}
      >
        TINYTOTS
      </Typography>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <RouterLink to="/" sx={{ textDecoration: 'none' }}>
      {logo}
    </RouterLink>
  );
}
