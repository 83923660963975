/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Avatar,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Chip,
  TextField,
  InputAdornment,
} from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import api from 'src/services/api'; // Assuming the API service is imported here
import { styled } from '@mui/system';
import _ from 'lodash';
import DatePicker from 'react-datepicker';

const ClassroomAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: 56,
  height: 56,
}));

const ClassroomChip = styled(Chip)(({ theme }) => ({
  cursor: 'pointer',
  padding: theme.spacing(1, 2),
  fontSize: '16px',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  '&.selected': {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
}));

const StudentCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  cursor: 'pointer',
  '&:hover': {
    boxShadow: theme.shadows[6],
    transform: 'scale(1.02)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  },
  borderRadius: theme.shape.borderRadius,
}));

const StudentAvatar = styled(Avatar)(({ theme }) => ({
  marginRight: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
}));

const ReportContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const ClassroomReport = () => {
  const datePickerRef = useRef(null); // Reference to DatePicker
  const [classrooms, setClassrooms] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedClassroom, setSelectedClassroom] = useState('ALL');
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editableStudent, setEditableStudent] = useState(null); // Local state for editable fields
  const [isEditing, setIsEditing] = useState(false); // State to toggle edit mode
  const [searchQuery, setSearchQuery] = useState(''); // Search state
  const [selectedDate, setSelectedDate] = useState(new Date()); // State for selected date

  // Fetch classrooms from API
  const getClassrooms = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.classroomService();
      setClassrooms(response?.data || []);
    } catch (err) {
      setError('Error fetching classrooms');
    } finally {
      setLoading(false);
    }
  };

  // Fetch students based on selected classroom
  const getStudents = async (classroomId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.getStudents(classroomId);
      setStudents(response?.data || []);
    } catch (err) {
      setError('Error fetching students');
    } finally {
      setLoading(false);
    }
  };

  const fetchReport = async (studentId) => {
    setLoading(true);
    try {
      const response = await api.getDailyReport(studentId);
      const report = response?.data?.report || { student_id: studentId }; // Fix: Extract first report from array
      setEditableStudent(report);
      console.log(studentId);
    } catch (err) {
      setError('Error fetching report data');
    } finally {
      setLoading(false);
    }
  };

  const formatDateToSQL = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 since months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  // Example Usage
  const currentDate = new Date();
  const formattedDate = formatDateToSQL(currentDate);
  console.log(formattedDate); // Outputs: 2024-12-31 13:45:30 (current date in the required format)

  // Fetch report by studentId and date
  const fetchReportByDate = async (studentId, date) => {
    const formattedDate = formatDateToSQL(date); // Format the selected date

    setLoading(true);
    try {
      const response = await api.getDailyReportByDate(studentId, formattedDate); // Pass the formatted date
      const report = response?.data?.report || { student_id: studentId }; // Fix: Extract first report from array
      setEditableStudent(report);
      console.log(report);

      // if (response?.data?.reports) {
      // const report = response.data.reports[0] || {}; // Extract the first report if available
      // setEditableStudent(report);
      // } else {
      //   setEditableStudent(null);
      // }
    } catch (err) {
      setError('Error fetching report data');
    } finally {
      setLoading(false);
    }
  };

  // Save or update report data
  const saveReport = async (studentId) => {
    const formattedData = {
      student_id: editableStudent?.student_id,
      report_date: editableStudent.report_date, // Ensure report_date is also correctly passed
      notes: editableStudent.notes,
      naps: editableStudent.naps,
      meals: editableStudent.meals,
      diapers: editableStudent.diapers,
      bathroom: editableStudent.bathroom,
      medication: editableStudent.medication,
      activities: editableStudent.activities,
    };

    setLoading(true);
    try {
      await api.saveReport(formattedData); // Assuming saveReport is an API call to update the student report
      _.debounce(() => 'Report saved successfully', 500)();
      setIsEditing(false);
    } catch (err) {
      setError('Error saving report');
    } finally {
      setLoading(false);
    }
  };

  // Fetch selected student's profile
  const getStudentProfile = async (studentId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.getStudentProfile(studentId);
      const student = response?.data || null;
      setSelectedStudent(student);

      setEditableStudent({ ...student });
    } catch (err) {
      setError('Error fetching student profile');
    } finally {
      setLoading(false);
    }
  };

  const handleFieldChange = (field, value) => {
    setEditableStudent((prev) => ({ ...prev, [field]: value }));
  };

  const toggleEditMode = () => {
    setIsEditing((prev) => !prev);
  };

  const handleClassroomChange = (classroomId) => {
    if (classroomId !== selectedClassroom) {
      setSelectedClassroom(classroomId);
    }
  };

  const handleStudentClick = (student) => {
    setSelectedStudent(student);
    // fetchReport(student.id);
    fetchReportByDate(student.id, selectedDate);
  };

  useEffect(() => {
    getClassrooms();
  }, []); // Fetch classrooms only once

  useEffect(() => {
    getStudents(selectedClassroom);
  }, [selectedClassroom]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredStudents = students.filter(
    (student) =>
      student.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      student.last_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleDateChange = (date) => {
    setSelectedDate(date); // Update selected date
    if (selectedStudent) {
      fetchReportByDate(selectedStudent.id, date); // Fetch report for the selected student and date
    }
  };

  const handlePrintReport = () => {
    const reportContent = document.getElementById('student-report-content');
    const printWindow = window.open('', '', 'height=600,width=800');
    printWindow.document.write('<html><head><title>Student Report</title></head><body>');
    printWindow.document.write(reportContent.innerHTML);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  };

  const openDatePicker = () => {
    datePickerRef.current.setOpen(true); // Open the DatePicker
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h3" sx={{ marginBottom: 2 }} paragraph>
        Daily Reports
      </Typography>

      {/* Classroom Selection Section */}
      <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
        <Typography variant="h4" gutterBottom>
          Select Classroom
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'center' }}>
          <ClassroomChip
            label="ALL"
            onClick={() => handleClassroomChange('ALL')}
            className={selectedClassroom === 'ALL' ? 'selected' : ''}
          />
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
              <CircularProgress />
            </Box>
          ) : error ? (
            <Typography sx={{ color: 'error.main', textAlign: 'center', marginTop: 4 }}>{error}</Typography>
          ) : classrooms.length === 0 ? (
            <Typography sx={{ textAlign: 'center', marginTop: 4 }}>No classrooms available</Typography>
          ) : (
            classrooms.map((classroom) => (
              <ClassroomChip
                key={classroom.id}
                label={classroom.name}
                onClick={() => handleClassroomChange(classroom.id)}
                className={selectedClassroom === classroom.id ? 'selected' : ''}
              />
            ))
          )}
        </Box>
      </Paper>

      {/* Students List Section */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={5}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              height: 600,
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'primary.main',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: 'primary.dark',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <Typography variant="h6" gutterBottom>
              Students
            </Typography>
            {/* Search Section */}
            <TextField
              gutterBottom
              label="Search by name"
              variant="outlined"
              fullWidth
              value={searchQuery}
              onChange={handleSearchChange}
            />
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                <CircularProgress />
              </Box>
            ) : error ? (
              <Typography sx={{ color: 'error.main', textAlign: 'center', marginTop: 4 }}>{error}</Typography>
            ) : filteredStudents.length === 0 ? (
              <Typography>No students found.</Typography>
            ) : (
              filteredStudents.map((student) => (
                <StudentCard key={student.id} onClick={() => handleStudentClick(student)}>
                  <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                    <StudentAvatar>
                      {student.first_name?.[0]}
                      {student.last_name?.[0]}
                    </StudentAvatar>
                    <Typography>{`${student.first_name} ${student.last_name}`}</Typography>
                  </CardContent>
                </StudentCard>
              ))
            )}
          </Paper>
        </Grid>

        {/* Student Report Section */}
        <Grid item xs={12} md={7}>
          <Paper elevation={3} sx={{ p: 2, height: 600, overflowY: 'auto' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">Student Report</Typography>
              {selectedStudent && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <Box>
                    <Button
                      variant="outlined"
                      sx={{
                        width: 'auto',
                        padding: '8px 16px',
                      }}
                      onClick={openDatePicker}
                    >
                      Filter
                    </Button>
                    <DatePicker
                      style={{ display: 'none' }} // Hide DatePicker UI
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat="yyyy-MM-dd"
                      ref={datePickerRef} // Attach ref to DatePicker
                    />
                  </Box>

                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                      variant="outlined"
                      sx={{
                        width: 'auto',
                        padding: '8px 16px',
                      }}
                      onClick={toggleEditMode}
                    >
                      {isEditing ? 'View' : 'Edit'}
                    </Button>
                    {isEditing && (
                      <Button
                        variant="outlined"
                        sx={{
                          width: 'auto',
                          padding: '8px 16px',
                        }}
                        onClick={saveReport}
                      >
                        Save Report
                      </Button>
                    )}
                  </Box>

                  <Box>
                    <Button
                      variant="outlined"
                      sx={{
                        width: 'auto',
                        padding: '8px 16px',
                      }}
                      onClick={handlePrintReport}
                    >
                      Print
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
            <div id="student-report-content">
              {editableStudent ? (
                <ReportContent>
                  {['notes', 'naps', 'meals', 'diapers', 'bathroom', 'medication', 'activities'].map((field) => (
                    <Box key={field} sx={{ mb: 2 }}>
                      <Typography variant="h6">{field.replace('_', ' ')}:</Typography>
                      {isEditing ? (
                        <TextField
                          variant="outlined"
                          fullWidth
                          value={editableStudent?.[field] || ''}
                          onChange={(e) => handleFieldChange(field, e.target.value)}
                        />
                      ) : (
                        <Typography>{editableStudent?.[field] || `Add ${field.replace('_', ' ')}...`}</Typography>
                      )}
                    </Box>
                  ))}
                </ReportContent>
              ) : (
                <Typography>Select a student to view their report</Typography>
              )}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClassroomReport;
