import React, { useState, useEffect, useMemo } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Tabs,
  Tab,
  Avatar,
  Box,
  Button,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
  FormControlLabel,
  InputAdornment,
  Snackbar,
  List,
  ListItem,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import {
  HelpOutline as HelpIcon,
  Close as CloseIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { Calendar, dateFnsLocalizer, Views } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { setHours, setMinutes } from 'date-fns';
import endOfWeek from 'date-fns/endOfWeek';
import dashboardData from './data/dashboardData.json';
import '../index.css'; // Adjust the path if necessary
import englishLabels from './labels/englishLabels';
// eslint-disable-next-line import/order, import/no-unresolved
import apis from 'src/services/api';

// eslint-disable-next-line prefer-arrow-callback
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Lessons = () => {
  const [selectedClassroom, setSelectedClassroom] = useState(null);
  const [selectedClass, setSelectedClass] = useState('ALL');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [openAddLessonDialog, setOpenAddLessonDialog] = useState(false);
  const [classrooms, setClassrooms] = useState([]);
  const [staff, setStaff] = useState([]);
  const { classRatios, lessonDetails } = dashboardData;
  const [newLesson, setNewLesson] = useState({
    classroom_ids: [], // Change this from classroom_id to classroom_ids
    staff_id: '', // Changed from teacher_id to staff_id
    subject: '',
    date: new Date(),
    start_time: '',
    end_time: '',
    description: '',
  });
  const [selectAllClassrooms, setSelectAllClassrooms] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [currentDate, setCurrentDate] = useState(new Date());
  const [lessons, setLessons] = useState([]);
  const [dateRange, setDateRange] = useState({
    start: startOfWeek(new Date()),
    end: endOfWeek(new Date()),
  });
  const [lessonDetailsData, setLessonDetailsData] = useState([]); // State for lesson details
  const [selectedClassrooms, setSelectedClassrooms] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [lessonToDelete, setLessonToDelete] = useState(null);

  useEffect(() => {
    fetchClassrooms();
    fetchStaff();
    fetchLessons();
  }, [dateRange]);

  useEffect(() => {
    if (selectedClassroom) {
      fetchLessonDetails(selectedClassroom, currentDate);
    } else {
      setLessonDetailsData([]); // Clear lesson details if no classroom is selected
    }
  }, [selectedClassroom, currentDate]);

  const fetchLessons = async (date = currentDate) => {
    // Fetch lessons based on the current date
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/lessons`, {
        params: {
          start_date: date.toISOString().split('T')[0],
          end_date: date.toISOString().split('T')[0],
        },
      });
      setLessons(response.data);
    } catch (error) {
      console.error('Error fetching lessons:', error);
      setSnackbar({
        open: true,
        message: 'Error fetching lessons',
        severity: 'error',
      });
    }
  };

  const fetchLessonDetails = async (classroomId, date = currentDate) => {
    try {
      // console.log('Fetching lesson details for classroom:', classroomId);
      // console.log('Selected date:', date.toISOString().split('T')[0]);

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/lessons/details`, {
        params: {
          classroom_id: classroomId,
          date: date.toISOString().split('T')[0],
        },
      });

      // console.log('Lesson details response:', response.data);
      setLessonDetailsData(response?.data);
    } catch (error) {
      console.error('Error fetching lesson details:', error);
      setSnackbar({
        open: true,
        message:
          // eslint-disable-next-line prefer-template
          'Error fetching lesson details: ' + (error?.response?.data?.message || error.message),
        severity: 'error',
      });
    }
  };

  const getLessonDetails = () => {
    const formattedDate = selectedDate.toISOString().split('T')[0];
    if (selectedClass === 'ALL') {
      return Object.values(lessonDetails).flatMap((classData) => classData[formattedDate] || []);
    }
    return lessonDetails[selectedClass]?.[formattedDate] || {};
  };
  // const lessonDetailsData = getLessonDetails();
  const fetchClassrooms = async () => {
    try {
      const response = await apis.classroomService();
      setClassrooms(response?.data);
      if (response?.data.length > 0) {
        setSelectedClassroom(response?.data[0].id);
        fetchLessonDetails(response?.data[0].id);
      }
    } catch (error) {
      console.error('Error fetching classrooms:', error);
    }
  };

  const fetchStaff = async () => {
    try {
      const response = await apis.getStaff();
      // Filter staff to only include those with the role of "Teacher"
      const teachers = response?.data?.filter((staffMember) => staffMember?.role === 'Teacher');
      setStaff(teachers);
    } catch (error) {
      console.error('Error fetching staff:', error);
    }
  };

  const handleClassChange = (event, newValue) => {
    setSelectedClassroom(newValue);
    fetchLessonDetails(newValue);
  };

  const handleDateChange = (date) => {
    setCurrentDate(date);
    setDateRange({
      start: startOfWeek(date),
      end: endOfWeek(date),
    });
    fetchLessons(date);
    if (selectedClassroom) {
      fetchLessonDetails(selectedClassroom, date);
    }
  };

  const handleAddLessonOpen = () => {
    setOpenAddLessonDialog(true);
  };

  const handleAddLessonClose = () => {
    setOpenAddLessonDialog(false);
    setNewLesson({
      classroom_ids: [], // Change this from classroom_id to classroom_ids
      staff_id: '', // Changed from teacher_id to staff_id
      subject: '',
      date: new Date(),
      start_time: '',
      end_time: '',
      description: '',
    });
  };

  const handleSelectAllClassrooms = (event) => {
    setSelectAllClassrooms(event.target.checked);
    if (event.target.checked) {
      setNewLesson((prevState) => ({
        ...prevState,
        classroom_ids: classrooms.map((c) => c.id),
      }));
    } else {
      setNewLesson((prevState) => ({
        ...prevState,
        classroom_ids: [],
      }));
    }
  };

  const handleLessonInputChange = (event) => {
    const { name, value } = event.target;
    setNewLesson((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleLessonDateChange = (date) => {
    setNewLesson((prevState) => ({
      ...prevState,
      // eslint-disable-next-line object-shorthand
      date: date,
    }));
  };

  const handleAddLesson = async () => {
    // Convert start_time and end_time to Date objects for comparison if they exist
    const startDate = newLesson?.start_time
      ? new Date(`${newLesson?.date?.toDateString()} ${newLesson?.start_time}`)
      : null;
    const endDate = newLesson?.end_time ? new Date(`${newLesson?.date?.toDateString()} ${newLesson?.end_time}`) : null;

    if (startDate && endDate && endDate <= startDate) {
      setSnackbar({
        open: true,
        message: 'End time must be after start time',
        severity: 'error',
      });
      return;
    }

    try {
      const lessonToSend = {
        ...newLesson,
        date: newLesson?.date.toISOString().split('T')[0], // Convert Date object to ISO string
        staff_id: newLesson?.staff_id || null, // Use null if staff_id is not provided
        start_time: newLesson?.start_time || null, // Use null if start_time is not provided
        end_time: newLesson?.end_time || null, // Use null if end_time is not provided
      };

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/lessons`, lessonToSend);
      handleAddLessonClose();
      setSnackbar({
        open: true,
        message: 'Lesson saved successfully',
        severity: 'success',
      });
      // Update lessons state directly with the new lesson
      setLessons((prevLessons) => [...prevLessons, response?.data]);
    } catch (error) {
      console.error('Error adding lesson:', error);
      setSnackbar({
        open: true,
        message: `Error saving lesson: ${error.message}`,
        severity: 'error',
      });
    } finally {
      fetchLessonDetails(selectedClassroom, currentDate);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const locales = {
    'en-US': enUS,
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  // const events = useMemo(() => {
  //   return lessons.map((lesson) => ({
  //     title: lesson?.subject,
  //     start: new Date(`${lesson?.date}T${lesson?.start_time}`),
  //     end: new Date(`${lesson?.date}T${lesson?.end_time}`),
  //   }));
  // }, [lessons]);

  // const events = useMemo(() => {
  //   return lessons.map((lesson) => ({
  //     title: lesson?.subject,
  //     start: lesson?.start_time,
  //     end: lesson?.end_time,
  //   }));
  // }, [lessons]);

  const eventStyleGetter = (event) => {
    return {
      style: {
        backgroundColor: '#3174ad', // Event background color
        color: 'white', // Text color inside the event
        border: 'none', // No border
        borderRadius: '4px', // Rounded corners for events
        padding: '5px', // Space inside the event
        display: 'flex',
        flexDirection: 'column', // Stack content vertically
        fontSize: '12px',
      },
    };
  };

  const combineDateWithTime = (date, time) => {
    const fullDateTime = `${date}T${time}`; // Combines the given date with the time
    return new Date(fullDateTime); // Creates a Date object from the combined string
  };
  const events = useMemo(() => {
    const todayDate = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

    return lessons
      .map((lesson) => {
        const start = lesson?.start_time ? combineDateWithTime(todayDate, lesson?.start_time) : null;
        const end = lesson?.end_time ? combineDateWithTime(todayDate, lesson?.end_time) : null;

        return start && end
          ? {
              title: lesson?.subject,
              start,
              end,
            }
          : null;
      })
      .filter((event) => event); // Filter out null events
  }, [lessons]);

  const minTime = setHours(setMinutes(new Date(), 0), 1); // 5:00 AM
  const maxTime = setHours(setMinutes(new Date(), 0), 23); // 10:00 PM

  const dayPropGetter = (date) => {
    if (date.getHours() < 5 || date.getHours() >= 22) {
      return {
        className: 'rbc-off-range-bg',
      };
    }
    setSnackbar({ ...snackbar, open: false });
  };

  useEffect(() => {
    // console.log('Current lessons state:', lessons);
    console.log('Current events:', events);
  }, [lessons, events]);

  // Handle classroom selection
  const handleClassroomToggle = (classroomId) => {
    setSelectedClassrooms((prev) => {
      if (prev.includes(classroomId)) {
        return prev.filter((id) => id !== classroomId);
        // eslint-disable-next-line no-else-return
      } else {
        return [...prev, classroomId];
      }
    });
  };

  const handleDeleteClick = (lesson) => {
    setLessonToDelete(lesson);
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    if (!lessonToDelete) return;

    try {
      console.log('Deleting lesson:', lessonToDelete.id);
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/lessons/${lessonToDelete.id}`);
      setSnackbar({
        open: true,
        message: 'Lesson deleted successfully',
        severity: 'success',
      });
      fetchLessonDetails(selectedClassroom, currentDate);
      fetchLessons(currentDate);
    } catch (error) {
      console.error('Error deleting lesson:', error);
      setSnackbar({
        open: true,
        message:
          // eslint-disable-next-line prefer-template
          'Error deleting lesson: ' + (error.response?.data?.message || error.message),
        severity: 'error',
      });
    } finally {
      setOpenDeleteDialog(false);
      setLessonToDelete(null);
    }
  };

  const handleEditClick = (lesson) => {
    setNewLesson({
      ...lesson,
      classroom_ids: [lesson.classroom_id],
      date: new Date(lesson.date),
    });
    setOpenAddLessonDialog(true);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {englishLabels?.lessons.title}
        </Typography>
        <Tooltip title="Plan, schedule, and manage lessons for different classrooms.">
          <IconButton size="small" sx={{ ml: 1, width: 20, height: 20 }}>
            <HelpIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      <Button variant="contained" color="primary" sx={{ mb: 2 }} onClick={handleAddLessonOpen}>
        {englishLabels?.lessons.addLesson}
      </Button>

      <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
        <Tabs
          value={selectedClassroom}
          onChange={handleClassChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="classroom tabs"
        >
          {classrooms?.map((classroom) => (
            <Tab
              key={classroom.id}
              label={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Avatar sx={{ bgcolor: 'primary.main', width: 56, height: 56 }}>{classroom?.capacity}</Avatar>
                  <Typography variant="caption">{classroom?.name}</Typography>
                </Box>
              }
              value={classroom?.id}
            />
          ))}
        </Tabs>
      </Paper>
      <Grid container spacing={3}>
        <Grid item xs={7}>
          <Paper elevation={3} sx={{ p: 2, height: 700, overflowY: 'auto' }}>
            <Typography variant="h6" gutterBottom>
              Daily Calendar
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              {/* <DatePicker
                selected={currentDate}
                onChange={handleDateChange}
                customInput={<Button variant="outlined">Select Date</Button>}
              />
              <Box>
                <Button onClick={() => setCurrentDate(new Date())}>Today</Button>
                <Button onClick={() => setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() - 1)))}>
                  Back
                </Button>
                <Button onClick={() => setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() + 1)))}>
                  Next
                </Button>
              </Box> */}
            </Box>
            <Calendar
              localizer={localizer}
              events={events} // Pass the correctly formatted events
              startAccessor="start" // Use 'start' for start time
              endAccessor="end" // Use 'end' for end time
              style={{ height: 600 }}
              defaultView={Views.MONTH} // Set default view to Month
              views={{ month: true }} // Only show Month view
              onNavigate={(newDate) => handleDateChange(newDate)}
              eventPropGetter={eventStyleGetter} // Custom styling for events
              min={minTime}
            />
          </Paper>
        </Grid>

        <Grid item xs={5}>
          <Paper elevation={3} sx={{ p: 2, height: 400, overflowY: 'auto' }}>
            <Typography variant="h6" gutterBottom>
              {englishLabels?.lessons?.lessonDetails}
            </Typography>
            {Array.isArray(lessonDetailsData) && lessonDetailsData?.length > 0 ? (
              lessonDetailsData?.map((lesson, index) => (
                <Box
                  key={index}
                  sx={{
                    mb: 2,
                    p: 2,
                    border: '1px solid #e0e0e0',
                    borderRadius: '4px',
                  }}
                >
                  <Typography variant="subtitle1" gutterBottom>
                    {lesson?.subject}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Time: {lesson?.start_time} - {lesson?.end_time}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Teacher: {lesson?.staff_name || 'Not assigned'}
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    {lesson?.description || 'No description provided'}
                  </Typography>
                  <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton onClick={() => handleEditClick(lesson)} color="primary">
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteClick(lesson)} color="error">
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
              ))
            ) : (
              <Typography variant="body1">No lesson details available for the selected date and classroom.</Typography>
            )}
          </Paper>
        </Grid>
        {/* <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              Select Classrooms
            </Typography>
            <List>
              {classrooms.map((classroom) => (
                <ListItem key={classroom.id} button onClick={() => handleClassroomToggle(classroom.id)}>
                  <ListItemText primary={classroom.name} />
                  <Checkbox
                    edge="end"
                    checked={selectedClassrooms.includes(classroom.id)}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid> */}
      </Grid>
      <Dialog open={openAddLessonDialog} onClose={handleAddLessonClose}>
        <DialogTitle> {newLesson?.id ? 'Update Lesson' : 'Add NewLesson'}</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={
              <Checkbox checked={selectAllClassrooms} onChange={handleSelectAllClassrooms} name="selectAllClassrooms" />
            }
            label="Select All Classrooms"
          />
          <FormControl fullWidth margin="normal" disabled={selectAllClassrooms}>
            <InputLabel>Classrooms</InputLabel>
            <Select
              multiple
              name="classroom_ids"
              value={newLesson?.classroom_ids}
              onChange={handleLessonInputChange}
              renderValue={(selected) => selected?.map((id) => classrooms?.find((c) => c?.id === id)?.name).join(', ')}
            >
              {classrooms.map((classroom) => (
                <MenuItem key={classroom?.id} value={classroom?.id}>
                  <Checkbox checked={newLesson?.classroom_ids?.includes(classroom?.id)} />
                  <ListItemText primary={classroom?.name} />
                  {/* <IconButton
                    onClick={(event) => {
                      event.stopPropagation();
                      // handleRemoveClassroom(classroom.id);
                    }}
                    size="small"
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton> */}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Teacher (Optional)</InputLabel>
            <Select name="staff_id" value={newLesson?.staff_id} onChange={handleLessonInputChange}>
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {staff.map((teacher) => (
                <MenuItem key={teacher?.id} value={teacher?.id}>
                  {`${teacher?.firstName} ${teacher?.lastName}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="normal"
            name="subject"
            label="Subject"
            type="text"
            fullWidth
            value={newLesson?.subject}
            onChange={handleLessonInputChange}
            required
          />
          <DatePicker
            selected={newLesson?.date}
            onChange={handleLessonDateChange}
            customInput={<TextField fullWidth margin="normal" label="Date" required />}
          />
          <TextField
            margin="normal"
            name="start_time"
            label="Start Time (Optional)"
            type="time"
            fullWidth
            value={newLesson?.start_time}
            onChange={handleLessonInputChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
          />
          <TextField
            margin="normal"
            name="end_time"
            label="End Time (Optional)"
            type="time"
            fullWidth
            value={newLesson?.end_time}
            onChange={handleLessonInputChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
          />
          <TextField
            margin="normal"
            name="description"
            label="Description"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={newLesson?.description}
            onChange={handleLessonInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddLessonClose}>Cancel</Button>
          <Button
            onClick={handleAddLesson}
            variant="contained"
            color="primary"
            disabled={!newLesson?.classroom_ids?.length || !newLesson?.subject}
          >
            {newLesson?.id ? 'Update Lesson' : 'Add Lesson'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackbar?.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar?.severity} sx={{ width: '100%' }}>
          {snackbar?.message}
        </Alert>
      </Snackbar>
      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this lesson?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Lessons;
