/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  Box,
  Tabs,
  Tab,
  Avatar,
  Tooltip,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  CircularProgress,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  CardContent,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { HelpOutline as HelpIcon } from '@mui/icons-material';
// eslint-disable-next-line import/no-unresolved
import api from 'src/services/api';
import { styled } from '@mui/system';

// Mock data for the enrollment pipeline

const StudentCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  cursor: 'pointer',
  '&:hover': {
    boxShadow: theme.shadows[6],
    transform: 'scale(1.02)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  },
  borderRadius: theme.shape.borderRadius,
}));

const Enrollment = () => {
  const [classrooms, setClassrooms] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedStage, setSelectedStage] = useState('active');
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [activeStudent, setActiveStudent] = useState(null);
  const [inactiveStudent, setInactiveStudent] = useState(null);
  const [waitlistStudent, setWaitlistStudent] = useState(null);
  const [graduatedStudent, setGraduatedStudent] = useState(null);
  const [scheduledStudent, setScheduledStudent] = useState(null);
  const [visitedStudent, setVisitedStudent] = useState(null);
  const [interestedStudent, setInterestedStudent] = useState(null);
  const [touringStudent, setTouringStudent] = useState(null);
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openParentModal, setOpenParentModal] = useState(false);
  const [openChildModal, setOpenChildModal] = useState(false);
  const [selectedParent, setSelectedParent] = useState(null);
  const [status, setStatus] = useState('');

  const [parentForm, setParentForm] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    address: '',
    user_id: '', // optional field
    email: '',
    children: [], // Store selected children (students)
  });

  const [childForm, setChildForm] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    classroomId: '',
    parentId: '',
  });

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Show snackbar with message
  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const getStudentsByStage = async (stage) => {
    setLoading(true);
    setError(null);

    try {
      let response;
      switch (stage) {
        case 'active':
          // eslint-disable-next-line no-case-declarations
          const data2 = await api.getActiveStudent();
          response = data2?.data?.students;
          break;
        case 'touring':
          response = touringStudent;
          break;
        case 'interested':
          response = interestedStudent;
          break;
        case 'visited':
          response = visitedStudent;
          break;
        case 'waitlist':
          response = waitlistStudent;
          break;
        case 'scheduled':
          response = scheduledStudent;
          break;
        case 'disenrolling':
          response = graduatedStudent;
          break;
        case 'inactive':
          response = inactiveStudent;
          break;
        default:
          // eslint-disable-next-line no-case-declarations
          const data = await api.getActiveStudent();
          response = data?.data?.students;
          break;
      }

      setStudents(response);
    } catch (err) {
      setError('Error fetching students');
      // setStudents([]);
    } finally {
      setLoading(false);
    }
  };

  // Trigger API call whenever the selectedStage changes
  useEffect(() => {
    getStudentsByStage(selectedStage);
  }, [selectedStage]);

  // Fetch classrooms from API using classroomService (from api.js)
  const getClassrooms = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.classroomService(); // Fetch classrooms from api.js
      setClassrooms(response?.data || []);
    } catch (err) {
      setError('Error fetching classrooms');
    } finally {
      setLoading(false);
    }
  };
  // Fetch students based on selected classroom
  const getStudents = async (classroomId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.getStudents(classroomId); // Pass classroomId to API
      setStudents(response?.data || []);
    } catch (err) {
      setError('Error fetching students');
    } finally {
      setLoading(false);
    }
  };

  // Fetch students based on selected classroom
  const getActiveStudents = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.getActiveStudent();
      setActiveStudent(response?.data?.students);
    } catch (err) {
      setError('Error fetching students');
    } finally {
      setLoading(false);
    }
  };
  const handleStudentClick = async (student) => {
    setSelectedStudent(student);
    setStatus(student.status);
    try {
      // Check if the student has a parent_id and fetch the parent details
      if (student?.parent_id) {
        const parentResponse = await api.getParentById(student?.parent_id);
        if (parentResponse?.data?.parent) {
          setSelectedParent(parentResponse?.data?.parent); // Set the parent details
        } else {
          console.error('Parent not found for this student.');
          setSelectedParent(null); // Clear parent if not found
        }
      } else {
        console.error('No parent_id found for the student.');
        setSelectedParent(null); // Clear parent if no parent_id
      }
    } catch (error) {
      console.error('Error fetching parent:', error);
      setSelectedParent(null); // Handle error and clear parent
    }
  };

  const getInactiveStudents = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.getInactiveStudent();
      setInactiveStudent(response?.data?.students);
    } catch (err) {
      setError('Error fetching students');
    } finally {
      setLoading(false);
    }
  };

  const getWaitlist = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.getWaitlist();
      setWaitlistStudent(response?.data?.students);
    } catch (err) {
      setError('Error fetching students');
    } finally {
      setLoading(false);
    }
  };

  const getGraduated = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.getGraduated();
      setGraduatedStudent(response?.data?.students);
    } catch (err) {
      setError('Error fetching students');
    } finally {
      setLoading(false);
    }
  };

  const getScheduled = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.getScheduled();
      setScheduledStudent(response?.data?.students);
    } catch (err) {
      setError('Error fetching students');
    } finally {
      setLoading(false);
    }
  };

  const getVisited = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.getVisited();
      setVisitedStudent(response?.data?.students);
    } catch (err) {
      setError('Error fetching students');
    } finally {
      setLoading(false);
    }
  };

  const getTouring = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.getTouring();
      setTouringStudent(response?.data?.students);
    } catch (err) {
      setError('Error fetching students');
    } finally {
      setLoading(false);
    }
  };

  const getInterested = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.getInterested();
      setInterestedStudent(response?.data?.students);
    } catch (err) {
      setError('Error fetching students');
    } finally {
      setLoading(false);
    }
  };

  const handleStageChange = (event, newValue) => {
    setSelectedStage(newValue);
    setSelectedStudent(null);
  };

  const handleSendNote = async () => {
    if (!content || !selectedStudent) {
      showSnackbar('Please enter a note and select a student.');
      return;
    }

    try {
      const body = {
        sender_id: selectedStudent.id, // Assuming selectedStudent contains student data
        content, // The note text
        message_type: 'text',
      };

      await api.sendNote(body); // Call the sendNote API

      showSnackbar('Note sent successfully!');
      setContent(''); // Clear the note input field
      setSelectedStudent(null); // Optionally clear the selected student
    } catch (error) {
      showSnackbar('Error sending note. Please try again.');
    }
  };

  // Handle adding a parent and assigning children
  const handleAddParent = async () => {
    try {
      // API call to add parent with selected children
      await api.createParent(parentForm);
      setOpenParentModal(false);
      setParentForm({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        children: [], // Reset children after adding parent
      });
      showSnackbar('Parent added successfully');
    } catch (error) {
      showSnackbar('Error adding parent');
    }
  };

  const [parentSearch, setParentSearch] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchError, setSearchError] = useState(null);

  // Open Child Modal
  const openChildDialog = async () => {
    setOpenChildModal(true);
    await fetchParents(); // Fetch parents when opening the modal
    setChildForm({
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      classroomId: '',
      parentId: '',
    });
  };

  const [parents, setParents] = useState([]);
  const [loadingParents, setLoadingParents] = useState(false);

  // Fetch Parents from API
  const fetchParents = async () => {
    setLoadingParents(true);
    try {
      const response = await api.getParentData(); // Adjust to correct API call
      if (response && response.data && Array.isArray(response.data.parents)) {
        setParents(response.data.parents);
      } else {
        console.error('Unexpected response structure:', response);
        setParents([]); // Fallback to an empty array if the response is not as expected
      }
    } catch (error) {
      console.error('Error fetching parents:', error);
      showSnackbar('Error fetching parents.');
    } finally {
      setLoadingParents(false);
    }
  };
  // Fetch all students from the API
  const getStudentsAll = async () => {
    setLoading(true);
    try {
      const response = await api.getStudentsAll();
      setStudents(response?.data?.students || []);
    } catch (err) {
      console.error('Error fetching students:', err);
    } finally {
      setLoading(false);
    }
  };

  // Handle checkbox change for adding children to the parent
  const handleChildrenChange = (event) => {
    const { value, checked } = event.target;
    setParentForm((prevState) => {
      const updatedChildren = checked
        ? [...prevState.children, value]
        : prevState.children.filter((childId) => childId !== value);
      return { ...prevState, children: updatedChildren };
    });
  };

  // Handle Add Child API Call
  // Handle Add Child API Call
  const handleAddChild = async () => {
    if (
      !childForm.firstName ||
      !childForm.lastName ||
      !childForm.dateOfBirth ||
      !childForm.classroomId ||
      !childForm.parentId
    ) {
      showSnackbar('Please fill all required fields.');
      return;
    }

    try {
      await api.createStudent({
        ...childForm,
      });
      showSnackbar('Child added successfully.');
      setOpenChildModal(false);
    } catch (error) {
      showSnackbar('Error adding child. Please try again.');
    }
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  // Open the parent modal and fetch students
  const openParentDialog = () => {
    setOpenParentModal(true);
    getStudentsAll(); // Fetch students when modal is opened
  };

  const pipelineData = {
    active: activeStudent?.length,
    interested: interestedStudent?.length,
    touring: touringStudent?.length,
    visited: visitedStudent?.length,
    waitlist: waitlistStudent?.length,
    scheduled: scheduledStudent?.length,
    disenrolling: graduatedStudent?.length,
    inactive: inactiveStudent?.length,
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleChangeStatus = async () => {
    if (!selectedStudent || !status) {
      alert('Please select a student and status.');
      return;
    }

    setLoading(true);
    try {
      const response = await api.updateStudentStatus(selectedStudent.id, status); // Update status API call
      getActiveStudents();
      getClassrooms();
      getInactiveStudents();
      getGraduated();
      getScheduled();
      getVisited();
      getInterested();
      getTouring();
      getWaitlist();
      setLoading(false);
      // You can also refetch the students or update the state here if necessary
    } catch (error) {
      console.error('Error updating status:', error);
      alert('Error updating status.');
      setLoading(false);
    }
  };

  useEffect(() => {
    getClassrooms();
    getActiveStudents();
    getInactiveStudents();
    getGraduated();
    getScheduled();
    getVisited();
    getInterested();
    getTouring();
    getWaitlist();
  }, []);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Enrollment Pipeline
        </Typography>

        <Tooltip title="Manage the enrollment process, from initial interest to active enrollment.">
          <IconButton size="small" sx={{ ml: 1, width: 20, height: 20 }}>
            <HelpIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      {/* Add Child Button */}
      <Button
        variant="contained"
        color="primary"
        sx={{ marginBottom: 2, marginRight: 2 }} // Added marginRight to create space between buttons
        onClick={openChildDialog}
      >
        Add Child
      </Button>

      {/* Add Parent Button */}
      <Button
        variant="contained"
        color="primary"
        sx={{ marginBottom: 2 }} // Space will be added between the buttons
        onClick={() => setOpenParentModal(true)}
      >
        Add Parent
      </Button>

      {/* Tabs Section */}
      <Paper
        elevation={6}
        sx={{ p: 2, mb: 2 }}
        style={{
          display: 'flex !important', // Force tabs to be horizontal
          overflowX: 'auto !important', // Ensure horizontal scrolling is possible
          flexWrap: 'nowrap !important', // Prevent tabs from wrapping to next line
        }}
      >
        <Tabs
          value={selectedStage}
          onChange={handleStageChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="enrollment pipeline tabs"
          style={{
            display: 'flex !important', // Force tabs to be horizontal
            overflowX: 'auto !important', // Ensure horizontal scrolling is possible
            flexWrap: 'nowrap !important', // Prevent tabs from wrapping to next line
          }}
        >
          {Object.entries(pipelineData).map(([stage, count]) => (
            <Tab
              key={stage}
              label={
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Avatar sx={{ bgcolor: 'primary.main', width: 56, height: 56 }}>{count}</Avatar>
                  <Typography variant="caption" sx={{ textAlign: 'center' }}>
                    {stage.charAt(0).toUpperCase() + stage.slice(1)}
                  </Typography>
                </Box>
              }
              value={stage}
            />
          ))}
        </Tabs>
      </Paper>

      {/* Main Content */}
      <Grid container spacing={3}>
        {/* Students List */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2, height: 600, overflowY: 'auto' }}>
            <Typography variant="h6" gutterBottom>
              Students - {selectedStage.charAt(0).toUpperCase() + selectedStage.slice(1)}
            </Typography>
            {loading ? (
              <CircularProgress />
            ) : error ? (
              <Typography color="error">{error}</Typography>
            ) : (
              <List>
                {students?.map((student) => (
                  <ListItem
                    key={student.id}
                    button
                    onClick={() => handleStudentClick(student)}
                    selected={selectedStudent?.id === student?.id}
                  >
                    <ListItemText
                      primary={`${student?.first_name} ${student?.last_name}`}
                      // secondary={`Parent: ${selectedParent.firstName} ${selectedParent.lastName}`}
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </Paper>
        </Grid>

        {/* Send Note Section */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2, height: 400, overflowY: 'auto' }}>
            <Typography variant="h6" gutterBottom>
              {selectedStage.charAt(0).toUpperCase() + selectedStage.slice(1)}
            </Typography>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Change Student Status
              </Typography>
              {selectedStudent ? (
                <>
                  <Typography variant="body1" gutterBottom>
                    Selected Student: {selectedStudent.first_name} {selectedStudent.last_name}
                  </Typography>

                  {/* Dropdown for Status Selection */}
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="status-label">Status</InputLabel>
                    <Select labelId="status-label" value={status} onChange={handleStatusChange} label="Status">
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="Inactive">Inactive</MenuItem>
                      <MenuItem value="waiting">Waitlist</MenuItem>
                      <MenuItem value="Touring">Touring</MenuItem>
                      <MenuItem value="Scheduled">Scheduled</MenuItem>
                      <MenuItem value="Graduated">Graduated</MenuItem>
                    </Select>
                  </FormControl>

                  {/* Button to Change Status */}
                  <Button variant="contained" color="primary" onClick={handleChangeStatus} disabled={loading}>
                    {loading ? 'Updating...' : 'Change Status'}
                  </Button>
                </>
              ) : (
                <Typography variant="body1">Select a student to change their status.</Typography>
              )}
            </Paper>
            <Typography variant="h6" gutterBottom>
              Send Note to Parent
            </Typography>
            {selectedParent ? (
              <>
                <Typography variant="body1" gutterBottom>
                  To: {selectedParent?.firstName} {selectedParent?.lastName} (Parent of {selectedStudent?.first_name})
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  label="Content"
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  sx={{ mb: 2 }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSendNote}
                  disabled={loading} // Disable the button while sending
                >
                  {loading ? 'Sending...' : 'Send Note'}
                </Button>
              </>
            ) : (
              <Typography variant="body1">Select a student from the list to send a note to their parent.</Typography>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* Students List Section */}

      {/* Add Child Dialog */}
      <Dialog open={openChildModal} onClose={() => setOpenChildModal(false)}>
        <DialogTitle>Add New Child</DialogTitle>
        <DialogContent>
          <TextField
            label="First Name"
            fullWidth
            margin="normal"
            value={childForm?.firstName}
            onChange={(e) => setChildForm({ ...childForm, firstName: e.target.value })}
          />
          <TextField
            label="Last Name"
            fullWidth
            margin="normal"
            value={childForm?.lastName}
            onChange={(e) => setChildForm({ ...childForm, lastName: e.target.value })}
          />
          <TextField
            label="Date of Birth"
            type="date"
            fullWidth
            margin="normal"
            value={childForm?.dateOfBirth}
            onChange={(e) => setChildForm({ ...childForm, dateOfBirth: e.target.value })}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="Classroom"
            select
            fullWidth
            margin="normal"
            value={childForm?.classroomId}
            onChange={(e) => setChildForm({ ...childForm, classroomId: e.target.value })}
            SelectProps={{ native: true }}
          >
            <option value="">Select Classroom</option>
            {classrooms?.map((classroom) => (
              <option key={classroom?.id} value={classroom?.id}>
                {classroom.name}
              </option>
            ))}
          </TextField>

          {/* Parent Dropdown */}
          <TextField
            label="Parent"
            select
            fullWidth
            margin="normal"
            value={childForm?.parentId}
            onChange={(e) => setChildForm({ ...childForm, parentId: e.target.value })}
            SelectProps={{ native: true }}
          >
            <option value="">Select Parent</option>
            {loadingParents ? (
              <option>Loading...</option>
            ) : parents.length > 0 ? (
              parents.map((parent) => (
                <option key={parent?.id} value={parent?.id}>
                  {`${parent?.firstName} ${parent?.lastName} (${parent?.phone || 'No Phone'})`}
                </option>
              ))
            ) : (
              <option>No parents available</option>
            )}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenChildModal(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddChild} color="primary">
            Add Child
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Parent Dialog */}
      <Dialog open={openParentModal} onClose={() => setOpenParentModal(false)}>
        <DialogTitle>Add Parent</DialogTitle>
        <DialogContent>
          <TextField
            label="First Name"
            fullWidth
            margin="normal"
            value={parentForm.firstName}
            onChange={(e) => setParentForm({ ...parentForm, firstName: e.target.value })}
          />
          <TextField
            label="Last Name"
            fullWidth
            margin="normal"
            value={parentForm.lastName}
            onChange={(e) => setParentForm({ ...parentForm, lastName: e.target.value })}
          />
          <TextField
            label="Phone"
            fullWidth
            margin="normal"
            value={parentForm.phone}
            onChange={(e) => setParentForm({ ...parentForm, phone: e.target.value })}
          />
          <TextField
            label="Email"
            fullWidth
            margin="normal"
            value={parentForm.email}
            onChange={(e) => setParentForm({ ...parentForm, email: e.target.value })}
          />

          {/* Children Selection */}
          <Typography variant="h6" sx={{ marginTop: 2 }}>
            Select Children
          </Typography>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
              <CircularProgress />
            </Box>
          ) : students?.length === 0 ? (
            <Typography>No students available</Typography>
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {students?.map?.((student) => (
                <FormControlLabel
                  key={student.id}
                  control={
                    <Checkbox
                      value={student.id}
                      checked={parentForm.children.includes(student.id.toString())}
                      onChange={handleChildrenChange}
                    />
                  }
                  label={`${student.first_name} ${student.last_name}`}
                />
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenParentModal(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddParent} color="primary">
            Add Parent
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Notifications */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Enrollment;
