/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Avatar,
  Button,
  Card,
  CardContent,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Divider,
  Grid,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import api from 'src/services/api'; // Assuming the API service is imported here
import { styled } from '@mui/system';

// Styled components for reusable styling
const ClassroomAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: 56,
  height: 56,
}));

// Styled components for reusable styling
const ClassroomChip = styled(Chip)(({ theme }) => ({
  cursor: 'pointer',
  padding: theme.spacing(1, 2),
  fontSize: '16px',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  '&.selected': {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
}));

const StudentCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  cursor: 'pointer',
  '&:hover': {
    boxShadow: theme.shadows[6],
    transform: 'scale(1.02)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  },
  borderRadius: theme.shape.borderRadius,
}));

const StudentAvatar = styled(Avatar)(({ theme }) => ({
  marginRight: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
}));

const ReportContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const Portfolio = () => {
  const [classrooms, setClassrooms] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedClassroom, setSelectedClassroom] = useState('ALL');
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openParentModal, setOpenParentModal] = useState(false);
  const [selectedParent, setSelectedParent] = useState(null); // State for parent data
  const [parentForm, setParentForm] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    children: [],
  });

  // Fetch classrooms from API using classroomService (from api.js)
  const getClassrooms = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.classroomService(); // Fetch classrooms from api.js
      setClassrooms(response?.data || []);
    } catch (err) {
      setError('Error fetching classrooms');
    } finally {
      setLoading(false);
    }
  };

  // Fetch students based on selected classroom
  const getStudents = async (classroomId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.getStudents(classroomId); // Pass classroomId to API
      setStudents(response?.data || []);
    } catch (err) {
      setError('Error fetching students');
    } finally {
      setLoading(false);
    }
  };

  // Fetch selected student's profile
  const getStudentProfile = async (studentId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.getStudentProfile(studentId);
      const studentData = response?.data || null;
      setSelectedStudent(studentData);

      // Fetch parent data if parent_id exists in student data
      if (studentData?.parent_id) {
        const parentResponse = await api.getParentById(studentData.parent_id);
        setSelectedParent(parentResponse?.data?.parent || null);
      } else {
        setSelectedParent(null);
      }
    } catch (err) {
      setError('Error fetching student profile');
    } finally {
      setLoading(false);
    }
  };

  // Handle classroom selection change
  const handleClassroomChange = (classroomId) => {
    setSelectedClassroom(classroomId);
  };

  // Handle student card click
  const handleStudentClick = (student) => {
    getStudentProfile(student.id);
  };

  // Handle print report
  const handlePrintReport = () => {
    const content = document.getElementById('student-report-content');
    const printWindow = window.open('', '_blank');
    printWindow.document.write(content.innerHTML);
    printWindow.document.close();
    printWindow.print();
  };

  // Fetch classrooms and students on component mount or when selectedClassroom changes
  useEffect(() => {
    getClassrooms();
  }, []);

  useEffect(() => {
    if (selectedClassroom) {
      // Fetch students when the classroom selection changes
      getStudents(selectedClassroom);
    }
  }, [selectedClassroom]);

  // Handle adding a new parent
  const handleAddParent = async () => {
    try {
      // API call to add parent and associate children
      await api.createParent(parentForm);
      setOpenParentModal(false);
      setParentForm({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        children: [],
      });
      alert('Parent added successfully');
    } catch (error) {
      alert('Error adding parent');
    }
  };

  // Handle checkbox change for adding children to parent
  const handleChildrenChange = (event) => {
    const { value, checked } = event.target;
    setParentForm((prevState) => {
      const updatedChildren = checked
        ? [...prevState.children, value]
        : prevState.children.filter((childId) => childId !== value);
      return { ...prevState, children: updatedChildren };
    });
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h3" sx={{ marginBottom: 2 }} paragraph>
        Portfolio
      </Typography>

      {/* Add Parent Button */}
      <Button variant="contained" color="primary" sx={{ marginBottom: 2 }} onClick={() => setOpenParentModal(true)}>
        Add Parent
      </Button>

      {/* Classroom Selection Section */}
      <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
        <Typography variant="h4" gutterBottom>
          Select Classroom
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'center' }}>
          <ClassroomChip
            label="ALL"
            onClick={() => handleClassroomChange('ALL')}
            className={selectedClassroom === 'ALL' ? 'selected' : ''}
          />
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
              <CircularProgress />
            </Box>
          ) : error ? (
            <Typography sx={{ color: 'error.main', textAlign: 'center', marginTop: 4 }}>{error}</Typography>
          ) : classrooms.length === 0 ? (
            <Typography sx={{ textAlign: 'center', marginTop: 4 }}>No classrooms available</Typography>
          ) : (
            classrooms.map((classroom) => (
              <ClassroomChip
                key={classroom.id}
                label={classroom.name}
                onClick={() => handleClassroomChange(classroom.id)}
                className={selectedClassroom === classroom.id ? 'selected' : ''}
              />
            ))
          )}
        </Box>
      </Paper>

      {/* Students List Section */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={5}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              height: 600,
              overflowY: 'auto',
            }}
          >
            <Typography variant="h6" gutterBottom>
              Students
            </Typography>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                <CircularProgress />
              </Box>
            ) : error ? (
              <Typography sx={{ color: 'error.main', textAlign: 'center', marginTop: 4 }}>{error}</Typography>
            ) : students.length === 0 ? (
              <Typography>No students found.</Typography>
            ) : (
              students.map((student) => (
                <StudentCard key={student.id} onClick={() => handleStudentClick(student)}>
                  <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                    <StudentAvatar>
                      {student.first_name?.[0]}
                      {student.last_name?.[0]}
                    </StudentAvatar>
                    <Typography>{`${student.first_name} ${student.last_name}`}</Typography>
                  </CardContent>
                </StudentCard>
              ))
            )}
          </Paper>
        </Grid>

        {/* Student Report Section */}
        <Grid item xs={12} md={7}>
          <Paper elevation={3} sx={{ p: 2, height: 600, overflowY: 'auto' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">Student Report</Typography>
              {selectedStudent && (
                <Button variant="outlined" sx={{ marginTop: 2 }} onClick={handlePrintReport}>
                  Print
                </Button>
              )}
            </Box>

            {selectedStudent ? (
              <ReportContent id="student-report-content">
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Avatar
                    src={selectedStudent.profile_picture}
                    sx={{ width: 60, height: 60, mr: 2, borderRadius: '50%' }}
                  />
                  <Typography variant="h6">{`${selectedStudent.first_name} ${selectedStudent.last_name}`}</Typography>
                </Box>
                <Divider sx={{ mb: 2 }} />
                <Typography>
                  Date of Birth:{' '}
                  {selectedStudent.date_of_birth
                    ? new Date(selectedStudent.date_of_birth).toLocaleDateString('en-GB')
                    : 'N/A'}
                </Typography>

                <Typography>Classroom: {selectedStudent.classroom_name || 'N/A'}</Typography>
                <Typography>Status: {selectedStudent.status || 'N/A'}</Typography>
                <Typography>Curriculum: {selectedStudent.curriculum || 'N/A'}</Typography>
                <Typography>Enrollment Type: {selectedStudent.enrollment_type || 'N/A'}</Typography>
                <Typography>
                  Enrollment Date:{' '}
                  {selectedStudent.enrollment_date
                    ? new Date(selectedStudent.enrollment_date).toLocaleDateString('en-GB')
                    : 'N/A'}
                </Typography>
                <Typography>
                  Enrollment Date:{' '}
                  {selectedStudent.graduation_date
                    ? new Date(selectedStudent.graduation_date).toLocaleDateString('en-GB')
                    : 'N/A'}
                </Typography>
                <Typography>Tour Date: {selectedStudent.tour_date || 'N/A'}</Typography>
                <Typography>Next Medical: {selectedStudent.next_medical || 'N/A'}</Typography>
                <Typography>Note: {selectedStudent.note || 'N/A'}</Typography>

                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  Parents
                </Typography>
                {selectedParent ? (
                  <Box>
                    <Typography>Name: {`${selectedParent.firstName} ${selectedParent.lastName}`}</Typography>
                    <Typography>Email: {selectedParent.email || 'N/A'}</Typography>
                    <Typography>Phone: {selectedParent.phone || 'N/A'}</Typography>
                  </Box>
                ) : (
                  <Typography>No parent information available</Typography>
                )}
              </ReportContent>
            ) : (
              <Typography>Select a student to view their report</Typography>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* Add Parent Dialog */}
      <Dialog open={openParentModal} onClose={() => setOpenParentModal(false)}>
        <DialogTitle>Add Parent</DialogTitle>
        <DialogContent>
          <TextField
            label="First Name"
            fullWidth
            margin="normal"
            value={parentForm.firstName}
            onChange={(e) => setParentForm({ ...parentForm, firstName: e.target.value })}
          />
          <TextField
            label="Last Name"
            fullWidth
            margin="normal"
            value={parentForm.lastName}
            onChange={(e) => setParentForm({ ...parentForm, lastName: e.target.value })}
          />
          <TextField
            label="Phone"
            fullWidth
            margin="normal"
            value={parentForm.phone}
            onChange={(e) => setParentForm({ ...parentForm, phone: e.target.value })}
          />
          <TextField
            label="Email"
            fullWidth
            margin="normal"
            value={parentForm.email}
            onChange={(e) => setParentForm({ ...parentForm, email: e.target.value })}
          />
          <FormControlLabel
            control={
              <Checkbox checked={parentForm.children.length > 0} onChange={() => handleChildrenChange} value="" />
            }
            label="Add Children"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenParentModal(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddParent} color="primary">
            Add Parent
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Portfolio;
