import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Link, Stack, TextField, IconButton, InputAdornment, Snackbar, Alert, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
// API service
import apis from '../../../services/api'; // Assuming this is your API instance

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // state for handling error message
  const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar visibility state

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      try {
        // Call the login API with form data (email, password)
        const response = await apis.login({
          email: values.email,
          password: values.password,
        });

        // Assuming the response contains an authToken
        if (response && response.data.authToken) {
          // console.log(response.data);

          // Store the token in localStorage
          localStorage.setItem('authToken', response.data.authToken);
          localStorage.setItem('userId', response?.data?.userId || '');
          // Redirect to dashboard
          navigate('/dashboard', { replace: true });
        } else {
          // Handle if no token is received (authentication failed)
          setErrorMessage('Login failed: No token received');
          setOpenSnackbar(true);
        }
      } catch (error) {
        // Handle error (show an error message in Snackbar or as red text)
        const errorText = error.response?.data?.error || 'Something went wrong.';
        setErrorMessage(`Login failed: ${errorText}`);
        setOpenSnackbar(true); // Open snackbar for error message
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  // Handle Snackbar close
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        {/* Display red error text below form */}
        {errorMessage && (
          <Stack sx={{ my: 2 }}>
            <Typography color="error" variant="body2">
              {errorMessage}
            </Typography>
          </Stack>
        )}

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {/* You can uncomment the remember me section if needed */}
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Login
        </LoadingButton>
      </Form>

      {/* Snackbar for error message */}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </FormikProvider>
  );
}

// import * as Yup from 'yup';
// import { useState } from 'react';
// import { Link as RouterLink, useNavigate } from 'react-router-dom';
// import { useFormik, Form, FormikProvider } from 'formik';
// // material
// import { Stack, TextField, IconButton, InputAdornment, Snackbar, Alert, Typography } from '@mui/material';
// import { LoadingButton } from '@mui/lab';
// // component
// import Iconify from '../../../components/Iconify';
// // API service
// import apis from '../../../services/api';

// export default function LoginForm() {
//   const navigate = useNavigate();

//   const [showPassword, setShowPassword] = useState(false);
//   const [errorMessage, setErrorMessage] = useState('');
//   const [openSnackbar, setOpenSnackbar] = useState(false);

//   const LoginSchema = Yup.object().shape({
//     email: Yup.string().email('Invalid email address').required('Email is required'),
//     password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
//   });

//   const formik = useFormik({
//     initialValues: {
//       email: '',
//       password: '',
//     },
//     validationSchema: LoginSchema,
//     onSubmit: async (values) => {
//       try {
//         const response = await apis.login({
//           email: values.email,
//           password: values.password,
//         });

//         if (response?.data?.authToken) {
//           localStorage.setItem('authToken', response.data.authToken);
//           localStorage.setItem('userId', response.data.userId || '');
//           navigate('/dashboard', { replace: true });
//         } else {
//           setErrorMessage('Login failed: No token received');
//           setOpenSnackbar(true);
//         }
//       } catch (error) {
//         const errorText = error.response?.data?.error || 'Something went wrong. Please try again.';
//         setErrorMessage(errorText);
//         setOpenSnackbar(true);
//         console.log('API Error:', error.response || error.message);
//       }
//     },
//   });

//   const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

//   const handleShowPassword = () => {
//     setShowPassword((show) => !show);
//   };

//   const handleSnackbarClose = () => {
//     setOpenSnackbar(false);
//   };

//   return (
//     <FormikProvider value={formik}>
//       <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
//         <Stack spacing={3}>
//           <TextField
//             fullWidth
//             autoComplete="username"
//             type="email"
//             label="Email address"
//             {...getFieldProps('email')}
//             error={Boolean(touched.email && errors.email)}
//             helperText={touched.email && errors.email}
//           />

//           <TextField
//             fullWidth
//             autoComplete="current-password"
//             type={showPassword ? 'text' : 'password'}
//             label="Password"
//             {...getFieldProps('password')}
//             InputProps={{
//               endAdornment: (
//                 <InputAdornment position="end">
//                   <IconButton onClick={handleShowPassword} edge="end">
//                     <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
//                   </IconButton>
//                 </InputAdornment>
//               ),
//             }}
//             error={Boolean(touched.password && errors.password)}
//             helperText={touched.password && errors.password}
//           />
//         </Stack>

//         {errorMessage && (
//           <Stack sx={{ my: 2 }}>
//             <Typography color="error" variant="body2">
//               {errorMessage}
//             </Typography>
//           </Stack>
//         )}

//         <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
//           Login
//         </LoadingButton>
//       </Form>

//       <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
//         <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
//           {errorMessage}
//         </Alert>
//       </Snackbar>
//     </FormikProvider>
//   );
// }
