import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';

// pages imports
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import UserChild from './pages/UserChild';

// PrivateRoute component import
import PrivateRoute from './PrivateRoute';
import Payments from './pages/Payments';
// eslint-disable-next-line import/extensions
import Messages from './pages/Messages.js';
// eslint-disable-next-line import/extensions
import Report from './pages/Report.js';
// eslint-disable-next-line import/named
import Enrollment from './pages/Enrollment';
import Portfolio from './pages/Portfolio';
import Attendance from './pages/Attendance';
import Gallery from './pages/Gallery';
import Lessons from './pages/Lessons';

export default function Router({
  setAttemptToAddUser,
  setAttemptToEditUser,
  setAttemptToAddTask,
  setAttemptToEditTask,
}) {
  // Check if the user is authenticated (e.g., by checking for a token in localStorage)
  const isAuthenticated = Boolean(localStorage.getItem('authToken'));
  return useRoutes([
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard" /> }, // Redirect root to /dashboard
        {
          path: 'login',
          element: isAuthenticated ? <Navigate to="/dashboard" /> : <Login />,
        },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '',
      element: (
        <PrivateRoute>
          <DashboardLayout />
        </PrivateRoute>
      ),
      children: [
        { path: 'dashboard', element: <DashboardApp /> }, // Same component for /dashboard
        { path: '/', element: <DashboardApp /> }, // Same component for root ("/")

        {
          path: 'users',
          element: (
            <PrivateRoute>
              <User setAttemptToAddUser={setAttemptToAddUser} setAttemptToEditUser={setAttemptToEditUser} />
            </PrivateRoute>
          ),
        },
        {
          path: 'users/:id',
          element: (
            <PrivateRoute>
              <UserChild />
            </PrivateRoute>
          ),
        },
        {
          path: 'attendance',
          element: (
            <PrivateRoute>
              <Attendance setAttemptToAddTask={setAttemptToAddTask} setAttemptToEditTask={setAttemptToEditTask} />
            </PrivateRoute>
          ),
        },
        {
          path: 'gallery',
          element: (
            <PrivateRoute>
              <Gallery setAttemptToAddTask={setAttemptToAddTask} setAttemptToEditTask={setAttemptToEditTask} />
            </PrivateRoute>
          ),
        },
        {
          path: 'lessons',
          element: (
            <PrivateRoute>
              <Lessons setAttemptToAddTask={setAttemptToAddTask} setAttemptToEditTask={setAttemptToEditTask} />
            </PrivateRoute>
          ),
        },
        {
          path: 'billing',
          element: (
            <PrivateRoute>
              <Payments setAttemptToAddTask={setAttemptToAddTask} setAttemptToEditTask={setAttemptToEditTask} />
            </PrivateRoute>
          ),
        },
        {
          path: 'messages',
          element: (
            <PrivateRoute>
              <Messages setAttemptToAddTask={setAttemptToAddTask} setAttemptToEditTask={setAttemptToEditTask} />
            </PrivateRoute>
          ),
        },
        {
          path: 'report',
          element: (
            <PrivateRoute>
              <Report setAttemptToAddTask={setAttemptToAddTask} setAttemptToEditTask={setAttemptToEditTask} />
            </PrivateRoute>
          ),
        },
        {
          path: 'portfolio',
          element: (
            <PrivateRoute>
              <Portfolio setAttemptToAddTask={setAttemptToAddTask} setAttemptToEditTask={setAttemptToEditTask} />
            </PrivateRoute>
          ),
        },
        {
          path: 'enrollment',
          element: (
            <PrivateRoute>
              <Enrollment setAttemptToAddTask={setAttemptToAddTask} setAttemptToEditTask={setAttemptToEditTask} />
            </PrivateRoute>
          ),
        },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
