/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash/debounce';
import { Chart as ChartJS, ArcElement, Tooltip as ChartTooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  Box,
  Tabs,
  Tab,
  Avatar,
  Stack,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Snackbar,
  Alert,
  Select,
  InputLabel,
  FormControl,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItemSecondaryAction,
  Pagination,
} from '@mui/material';
import {
  HelpOutline as HelpIcon,
  CheckCircleOutline as CheckInIcon,
  ExitToApp as CheckOutIcon,
  Cancel as NotAttendingIcon,
  MoreVert as MoreIcon,
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
  CalendarToday as CalendarTodayIcon,
} from '@mui/icons-material';
// eslint-disable-next-line import/no-unresolved
import DatePicker from 'react-datepicker';
// eslint-disable-next-line import/no-unresolved
import 'react-datepicker/dist/react-datepicker.css';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DateFnsUtils from '@date-io/date-fns'; // Change this line
// eslint-disable-next-line import/named
import apis from '../services/api';
// eslint-disable-next-line import/no-unresolved
import englishLabels from './labels/englishLabels'; // Import the labels

const Attendance = () => {
  const [selectedClassroom, setSelectedClassroom] = useState('ALL');
  const [classrooms, setClassrooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const [openAddClassroomDialog, setOpenAddClassroomDialog] = useState(false);
  const [newClassroomName, setNewClassroomName] = useState('');
  const [newClassroomCapacity, setNewClassroomCapacity] = useState('');

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const [openAddStaffDialog, setOpenAddStaffDialog] = useState(false);
  const [newStaffFirstName, setNewStaffFirstName] = useState('');
  const [newStaffLastName, setNewStaffLastName] = useState('');
  const [newStaffEmail, setNewStaffEmail] = useState('');
  const [newStaffRole, setNewStaffRole] = useState('');
  const [newStaffClassrooms, setNewStaffClassrooms] = useState([]);
  const [newStaffHasAllAccess, setNewStaffHasAllAccess] = useState(false);
  const [availableClassrooms, setAvailableClassrooms] = useState([]);

  const [openClassroomSelect, setOpenClassroomSelect] = useState(false);

  const [openAddChildDialog, setOpenAddChildDialog] = useState(false);
  const [newChildFirstName, setNewChildFirstName] = useState('');
  const [newChildLastName, setNewChildLastName] = useState('');
  const [newChildDateOfBirth, setNewChildDateOfBirth] = useState('');
  const [newChildClassroom, setNewChildClassroom] = useState('');
  const [openChildClassroomSelect, setOpenChildClassroomSelect] = useState(false);

  const [parentSearch, setParentSearch] = useState('');
  const [selectedParent, setSelectedParent] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchError, setSearchError] = useState(null);
  const [newParentFirstName, setNewParentFirstName] = useState('');
  const [newParentLastName, setNewParentLastName] = useState('');
  const [newParentPhone, setNewParentPhone] = useState('');
  const [newParentEmail, setNewParentEmail] = useState('');
  const [newParentAddress, setNewParentAddress] = useState('');

  const [openAttendanceDialog, setOpenAttendanceDialog] = useState(false);
  const [attendanceType, setAttendanceType] = useState('');
  const [attendanceDate, setAttendanceDate] = useState(new Date().toISOString().split('T')[0]);
  const [attendanceStudents, setAttendanceStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [attendanceNotes, setAttendanceNotes] = useState('');

  const [page, setPage] = useState(1);
  const studentsPerPage = 10; // or whatever number you prefer
  const [searchTerm, setSearchTerm] = useState('');

  const [attendanceRecords, setAttendanceRecords] = useState([]);

  const [teachers, setTeachers] = useState([]);

  const [attendanceStats, setAttendanceStats] = useState({
    checkedIn: 0,
    checkedOut: 0,
    notAttending: 0,
    absent: 0,
  });

  const [classroomStats, setClassroomStats] = useState({});

  const [studentCounts, setStudentCounts] = useState({
    totalStudents: 0,
    checkedIn: 0,
    checkedOut: 0,
    notAttending: 0,
    absent: 0,
  });

  const [staffCounts, setStaffCounts] = useState({
    totalStaff: 0,
    checkedIn: 0,
    checkedOut: 0,
    notAttending: 0,
    absent: 0,
  });

  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);

  const debouncedSearch = useCallback(
    debounce(async (searchTerm) => {
      if (searchTerm.length < 3) {
        setSearchResults([]);
        return;
      }
      setIsSearching(true);
      setSearchError(null);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/parents/search?query=${searchTerm}`);
        setSearchResults(response.data);
      } catch (err) {
        // console.error('Error searching for parent:', err);
        setSearchError('Failed to search for parents. Please try again.');
      } finally {
        setIsSearching(false);
      }
    }, 300),
    []
  );

  const handleParentSearch = (event) => {
    const searchTerm = event.target.value;
    setParentSearch(searchTerm);
    debouncedSearch(searchTerm);
  };

  const fetchClassrooms = async () => {
    try {
      const response = await apis.classroomService();
      setClassrooms(response.data);
      setAvailableClassrooms(response.data);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching classrooms:', err);
      setError(`Failed to fetch classrooms: ${err.message}`);
      setLoading(false);
    }
  };

  const fetchAttendanceRecords = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/attendance`);
      setAttendanceRecords(response.data);
    } catch (err) {
      console.error('Error fetching attendance records:', err);
      let errorMessage = 'Error fetching attendance records';
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMessage += `: ${err.response.status} - ${err.response.data.message || err.response.data}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage += ': No response received from server';
      } else {
        // Something happened in setting up the request that triggered an Error
        errorMessage += `: ${err.message}`;
      }
      setSnackbar({ open: true, message: errorMessage, severity: 'error' });
    }
  };

  const fetchTeachers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/staff`);
      setTeachers(response.data);
    } catch (error) {
      console.error('Error fetching teachers:', error);
      setSnackbar({
        open: true,
        message: 'Error fetching teachers',
        severity: 'error',
      });
    }
  };

  const fetchAttendanceStats = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/attendance/stats`);
      setAttendanceStats(response.data);
    } catch (err) {
      console.error('Error fetching attendance stats:', err);
      setSnackbar({
        open: true,
        message: 'Error fetching attendance stats',
        severity: 'error',
      });
    }
  };

  const fetchClassroomStats = async (classroomId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/attendance/stats?classroomId=${classroomId}`
      );
      setClassroomStats((prevStats) => ({
        ...prevStats,
        [classroomId]: response.data,
      }));
    } catch (err) {
      console.error('Error fetching classroom stats:', err);
      setSnackbar({
        open: true,
        message: 'Error fetching classroom stats',
        severity: 'error',
      });
    }
  };

  const fetchAttendanceStudents = async (type, classroomId) => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/attendance/students?date=${attendanceDate}&status=${type}${
        classroomId !== 'ALL' ? `&classroomId=${classroomId}` : ''
      }`;
      const response = await axios.get(url);
      console.log('Fetched attendance students:', response.data); // Add this line
      setAttendanceStudents(response.data);
    } catch (error) {
      console.error('Error fetching attendance students:', error);
      setSnackbar({
        open: true,
        message: 'Error fetching students for attendance',
        severity: 'error',
      });
      setAttendanceStudents([]); // Set to empty array on error
    }
  };

  const fetchTodayAttendance = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/attendance/today?date=${selectedDate}`
      );
      setAttendanceRecords(response.data);
    } catch (error) {
      console.error('Error fetching attendance:', error);
      setSnackbar({
        open: true,
        message: 'Error fetching attendance',
        severity: 'error',
      });
    }
  };

  const fetchStudentCounts = async (classroomId) => {
    try {
      console.log(`Fetching student counts for classroom: ${classroomId}`);
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/attendance/student-counts?classroomId=${classroomId}`
      );
      console.log('Student counts response:', response.data);
      setStudentCounts(response.data);
    } catch (error) {
      console.error('Error fetching student counts:', error);
      console.error('Error details:', error.response?.data || error.message);
      setSnackbar({
        open: true,
        message: `Error fetching student counts: ${error.response?.data?.error || error.message}`,
        severity: 'error',
      });
    }
  };

  const fetchStaffCounts = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/staff/counts`);
      setStaffCounts(response.data);
    } catch (error) {
      console.error('Error fetching staff counts:', error);
      setSnackbar({
        open: true,
        message: 'Error fetching staff counts',
        severity: 'error',
      });
    }
  };

  useEffect(() => {
    fetchClassrooms();
    fetchTeachers();
    fetchAttendanceStats();
    fetchTodayAttendance();
    // fetchStaffCounts();
  }, []);

  useEffect(() => {
    if (selectedClassroom !== 'ALL') {
      fetchClassroomStats(selectedClassroom);
      fetchStudentCounts(selectedClassroom);
    } else {
      fetchStudentCounts('ALL');
    }
  }, [selectedClassroom]);

  useEffect(() => {
    if (selectedClassroom !== 'ALL') {
      fetchAttendanceStudents(attendanceType, selectedClassroom);
    }
  }, [selectedClassroom, attendanceType]);

  // Add this useEffect for polling
  useEffect(() => {
    fetchTodayAttendance();
    const interval = setInterval(fetchTodayAttendance, 30000); // Poll every 30 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchTodayAttendance();
  }, [selectedDate]);

  const handleClassChange = (event, newValue) => {
    setSelectedClassroom(newValue);
    if (newValue !== 'ALL') {
      fetchClassroomStats(newValue);
      fetchStudentCounts(newValue);
    } else {
      fetchStudentCounts('ALL');
    }
    fetchAttendanceStudents(attendanceType, newValue);
  };

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreClose = () => {
    setAnchorEl(null);
  };

  const handleOpenAddClassroomDialog = () => {
    setOpenAddClassroomDialog(true);
  };

  const handleCloseAddClassroomDialog = () => {
    setOpenAddClassroomDialog(false);
    setNewClassroomName('');
    setNewClassroomCapacity('');
  };

  const handleAddClassroom = async () => {
    try {
      const response = await apis.classroomService({
        name: newClassroomName,
        // eslint-disable-next-line radix
        capacity: newClassroomCapacity ? parseInt(newClassroomCapacity) : null,
        userId: 1, // Hardcoded for now, replace with actual user ID when you have authentication
      });
      console.log('Classroom added:', response.data);
      handleCloseAddClassroomDialog();
      fetchClassrooms(); // This will now work correctly
      setSnackbar({
        open: true,
        message: 'Classroom added successfully',
        severity: 'success',
      });
    } catch (err) {
      console.error('Error adding classroom:', err);
      setSnackbar({
        open: true,
        message: `Error adding classroom: ${err.response?.data?.error || err.message}`,
        severity: 'error',
      });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleOpenAddStaffDialog = () => {
    setOpenAddStaffDialog(true);
  };

  const handleCloseAddStaffDialog = () => {
    setOpenAddStaffDialog(false);
    setNewStaffFirstName('');
    setNewStaffLastName('');
    setNewStaffEmail('');
    setNewStaffRole('');
    setNewStaffClassrooms([]);
    setNewStaffHasAllAccess(false);
  };

  const handleAddStaff = async () => {
    try {
      const response = await apis.staffService({
        firstName: newStaffFirstName,
        lastName: newStaffLastName,
        email: newStaffEmail,
        role: newStaffRole,
        classroomIds: newStaffHasAllAccess ? [] : newStaffClassrooms,
        hasAllAccess: newStaffHasAllAccess,
        userId: 1, // Hardcoded for now, replace with actual user ID when you have authentication
      });
      console.log('Staff added:', response.data);
      handleCloseAddStaffDialog();
      setSnackbar({
        open: true,
        message: 'Staff added successfully',
        severity: 'success',
      });
    } catch (err) {
      console.error('Error adding staff:', err);
      setSnackbar({
        open: true,
        message: `Error adding staff: ${err.response?.data?.error || err.message}`,
        severity: 'error',
      });
    }
  };

  const handleOpenClassroomSelect = () => {
    setOpenClassroomSelect(true);
  };

  const handleCloseClassroomSelect = () => {
    setOpenClassroomSelect(false);
  };

  const handleClassroomToggle = (classroomId) => {
    const currentIndex = newStaffClassrooms.indexOf(classroomId);
    const newChecked = [...newStaffClassrooms];

    if (currentIndex === -1) {
      newChecked.push(classroomId);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setNewStaffClassrooms(newChecked);
  };

  const handleOpenAddChildDialog = () => {
    setOpenAddChildDialog(true);
  };

  const handleCloseAddChildDialog = () => {
    setOpenAddChildDialog(false);
    setNewChildFirstName('');
    setNewChildLastName('');
    setNewChildDateOfBirth('');
    setNewChildClassroom('');
  };

  const handleOpenChildClassroomSelect = () => {
    setOpenChildClassroomSelect(true);
  };

  const handleCloseChildClassroomSelect = () => {
    setOpenChildClassroomSelect(false);
  };

  const handleChildClassroomToggle = (classroomId) => {
    setNewChildClassroom(classroomId);
  };

  const handleSelectParent = (parent) => {
    setSelectedParent(parent);
    setSearchResults([]);
    setParentSearch('');
  };

  const handleRemoveSelectedParent = () => {
    setSelectedParent(null);
  };

  const handleAddParent = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/parents`, {
        firstName: newParentFirstName,
        lastName: newParentLastName,
        phone: newParentPhone,
        email: newParentEmail,
        address: newParentAddress,
        userId: 1, // Hardcoded for now, replace with actual user ID when you have authentication
      });
      setSelectedParent(response.data);
      setSnackbar({
        open: true,
        message: 'Parent added successfully',
        severity: 'success',
      });
    } catch (err) {
      console.error('Error adding parent:', err);
      setSnackbar({
        open: true,
        message: `Error adding parent: ${err.message}`,
        severity: 'error',
      });
    }
  };

  const handleAddChild = async () => {
    if (!newChildFirstName || !newChildLastName || !newChildDateOfBirth || !newChildClassroom || !selectedParent) {
      setSnackbar({
        open: true,
        message: 'Please fill all required fields',
        severity: 'error',
      });
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/children`, {
        firstName: newChildFirstName,
        lastName: newChildLastName,
        dateOfBirth: newChildDateOfBirth,
        classroomId: newChildClassroom,
        parentId: selectedParent.id,
        userId: 1, // Hardcoded for now, replace with actual user ID when you have authentication
      });
      console.log('Child added:', response.data);
      handleCloseAddChildDialog();
      setSnackbar({
        open: true,
        message: 'Child added successfully',
        severity: 'success',
      });
      // Optionally, refresh the list of children here
    } catch (err) {
      console.error('Error adding child:', err);
      setSnackbar({
        open: true,
        message: `Error adding child: ${err.response?.data?.error || err.message}`,
        severity: 'error',
      });
    }
  };

  const handleAttendanceClick = async (type) => {
    const statusMap = {
      check_in: 'checked_in',
      check_out: 'checked_out',
      not_attending: 'not_attending',
    };

    const mappedType = statusMap[type];
    if (!mappedType) {
      setSnackbar({
        open: true,
        message: 'Invalid attendance type',
        severity: 'error',
      });
      return;
    }

    setAttendanceType(mappedType);
    setOpenAttendanceDialog(true);

    // Reset states
    setSearchTerm('');
    setSelectedStudents([]);
    setAttendanceNotes('');
    setPage(1);

    fetchAttendanceStudents(mappedType, selectedClassroom);
  };

  const handleStudentSelect = (studentId) => {
    setSelectedStudents((prev) =>
      prev.includes(studentId) ? prev.filter((id) => id !== studentId) : [...prev, studentId]
    );
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(1);
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const filteredStudents = attendanceStudents.filter(
    (student) =>
      student &&
      typeof student === 'object' &&
      `${student.first_name || ''} ${student.last_name || ''}`.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedStudents = filteredStudents.slice((page - 1) * studentsPerPage, page * studentsPerPage);

  const renderCircles = (data) => (
    <Grid container spacing={2}>
      {paginatedStudents?.map((item) => (
        <Grid item xs={12} sm={6} md={2.4} key={item?.id || 'unknown'}>
          <Box
            onClick={() => item?.id && handleStudentSelect(item.id)}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              cursor: 'pointer',
              p: 1,
              borderRadius: 2,
              bgcolor: item?.id && selectedStudents?.includes(item.id) ? 'primary.light' : 'background.paper',
            }}
          >
            <Avatar
              sx={{
                width: 80,
                height: 80,
                fontSize: 32,
                mb: 1,
                bgcolor: item?.id && selectedStudents?.includes(item.id) ? 'primary.main' : 'secondary.main',
              }}
            >
              {(item?.firstName?.[0] || '') + (item?.lastName?.[0] || '')}
            </Avatar>
            <Typography variant="subtitle2" align="center">
              {item?.firstName || 'Unknown'} {item?.lastName || ''}
            </Typography>
            <Typography variant="caption" align="center">
              {item?.classroom_name || 'No Classroom'}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );

  const handleAttendanceSubmit = async () => {
    const validStatuses = ['checked_in', 'checked_out', 'not_attending'];

    if (!validStatuses?.includes(attendanceType)) {
      setSnackbar({
        open: true,
        message: 'Invalid attendance status',
        severity: 'error',
      });
      return;
    }

    try {
      // eslint-disable-next-line no-restricted-syntax
      for (const studentId of selectedStudents) {
        // eslint-disable-next-line no-await-in-loop
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/attendance`, {
          studentId,
          date: attendanceDate,
          status: attendanceType,
          notes: attendanceNotes,
          userId: 1, // Replace with actual user ID when authentication is implemented
        });
        console.log('Attendance response:', response.data); // Add this log
      }
      setSnackbar({
        open: true,
        message: 'Attendance updated successfully',
        severity: 'success',
      });
      setOpenAttendanceDialog(false);
      setSelectedStudents([]);
      setAttendanceNotes('');
      await fetchTodayAttendance(); // Refetch attendance data
      await fetchStudentCounts(selectedClassroom);
      await fetchAttendanceStats();
    } catch (error) {
      console.error('Error updating attendance:', error);
      setSnackbar({
        open: true,
        message: `Error updating attendance: ${error.message}`,
        severity: 'error',
      });
    }
  };

  const renderAttendanceList = () => {
    return (
      <List>
        {attendanceRecords.map((record) => {
          const recordDate = new Date(record.date);
          const formattedDate = recordDate.toLocaleDateString();
          // const checkInTime = record.check_in_time ? new Date(record.check_in_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : 'N/A';
          // const checkOutTime = record.check_out_time ? new Date(record.check_out_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : 'N/A';

          return (
            <ListItem key={record?.id}>
              <ListItemText
                primary={`${record?.first_name} ${record?.last_name} - ${record?.classroom_name}`}
                secondary={
                  <>
                    <Typography component="span" variant="body2" color="textPrimary">
                      Status: {record?.status}
                    </Typography>
                    <br />
                    <Typography component="span" variant="body2">
                      Date: {formattedDate}
                    </Typography>
                    <br />
                    {/* <Typography component="span" variant="body2">
                      Check-in: {checkInTime}
                    </Typography>
                    <br />
                    <Typography component="span" variant="body2">
                      Check-out: {checkOutTime}
                    </Typography> */}
                    {record?.notes && (
                      <>
                        <br />
                        <Typography component="span" variant="body2">
                          Notes: {record?.notes}
                        </Typography>
                      </>
                    )}
                  </>
                }
              />
            </ListItem>
          );
        })}
      </List>
    );
  };

  const attendanceData = {
    labels: ['Checked In', 'Checked Out', 'Not Attending', 'Absent'],
    datasets: [
      {
        data: [
          attendanceStats.checkedIn,
          attendanceStats.checkedOut,
          attendanceStats.notAttending,
          attendanceStats.absent,
        ],
        backgroundColor: ['#4CAF50', '#FFC107', '#F44336', '#9E9E9E'],
        hoverBackgroundColor: ['#45a049', '#e6ac00', '#da190b', '#7d7d7d'],
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || '';
            const value = context.parsed || 0;
            const total = context.dataset.data.reduce((acc, data) => acc + data, 0);
            const percentage = ((value / total) * 100).toFixed(1);
            return `${label}: ${value} (${percentage}%)`;
          },
        },
      },
    },
  };

  const renderAttendanceDonut = () => (
    <Box sx={{ height: 300, position: 'relative' }}>
      <Doughnut data={attendanceData} options={chartOptions} />
      <Typography
        variant="h4"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        {attendanceStats?.checkedIn + attendanceStats?.checkedOut}
      </Typography>
      <Typography
        variant="caption"
        sx={{
          position: 'absolute',
          top: '60%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        {englishLabels?.attendance?.totalPresent}
      </Typography>
    </Box>
  );

  const getFilteredData = (type) => {
    if (type === 'students') {
      return attendanceStudents;
      // eslint-disable-next-line no-else-return
    } else if (type === 'teachers') {
      return teachers;
    }
    return [];
  };

  const renderClassroomStats = (classroomId) => {
    const stats = classroomStats[classroomId];
    if (!stats) return null;

    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle1">Today's Attendance:</Typography>
        <Typography>Checked In: {stats?.checkedIn}</Typography>
        <Typography>Checked Out: {stats?.checkedOut}</Typography>
        <Typography>Not Attending: {stats?.notAttending}</Typography>
        <Typography>Absent: {stats?.absent}</Typography>
      </Box>
    );
  };

  const renderStudentCounts = () => (
    <Box sx={{ mt: 2 }}>
      <Typography variant="subtitle1">Student Attendance:</Typography>
      <Typography>Total Students: {studentCounts?.totalStudents}</Typography>
      <Typography>Checked In: {studentCounts?.checkedIn}</Typography>
      <Typography>Checked Out: {studentCounts?.checkedOut}</Typography>
      <Typography>Not Attending: {studentCounts?.notAttending}</Typography>
      <Typography>Absent: {studentCounts?.absent}</Typography>
    </Box>
  );

  const renderStaffCounts = () => (
    <Box sx={{ mt: 2 }}>
      <Typography variant="subtitle1">Staff Attendance:</Typography>
      <Typography>Total Staff: {staffCounts?.totalStaff}</Typography>
      <Typography>Checked In: {staffCounts?.checkedIn}</Typography>
      <Typography>Checked Out: {staffCounts?.checkedOut}</Typography>
      <Typography>Not Attending: {staffCounts?.notAttending}</Typography>
      <Typography>Absent: {staffCounts?.absent}</Typography>
    </Box>
  );

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {englishLabels?.attendance?.title}
        </Typography>
        <Tooltip title="Manage and track student and staff attendance.">
          <IconButton size="small" sx={{ ml: 1, width: 20, height: 20 }}>
            <HelpIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <Button
          variant="contained"
          color="success"
          startIcon={<CheckInIcon />}
          onClick={() => handleAttendanceClick('check_in')}
        >
          {englishLabels?.attendance?.checkIn}
        </Button>
        <Button
          variant="contained"
          color="warning"
          startIcon={<CheckOutIcon />}
          onClick={() => handleAttendanceClick('check_out')}
        >
          {englishLabels?.attendance?.checkOut}
        </Button>
        <Button
          variant="contained"
          color="error"
          startIcon={<NotAttendingIcon />}
          onClick={() => handleAttendanceClick('not_attending')}
        >
          {englishLabels?.attendance?.notAttending}
        </Button>
        <Button variant="contained" color="primary" startIcon={<MoreIcon />} onClick={handleMoreClick}>
          {englishLabels?.attendance?.more}
        </Button>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMoreClose}>
          <MenuItem
            onClick={() => {
              handleMoreClose();
              handleOpenAddChildDialog();
            }}
          >
            {englishLabels?.attendance?.addChild}
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMoreClose();
              handleOpenAddStaffDialog();
            }}
          >
            {englishLabels?.attendance?.addStaff}
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMoreClose();
              handleOpenAddClassroomDialog();
            }}
          >
            {englishLabels?.attendance?.addClassroom}
          </MenuItem>
          <MenuItem onClick={handleMoreClose}>{englishLabels?.attendance?.reports}</MenuItem>
        </Menu>
      </Stack>
      <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
        <Tabs
          value={selectedClassroom}
          onChange={handleClassChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="classroom tabs"
        >
          <Tab label="ALL" value="ALL" />
          {classrooms?.map((classroom) => (
            <Tab
              key={classroom?.id}
              label={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Avatar sx={{ bgcolor: 'primary.main', width: 56, height: 56 }}>{classroom?.capacity}</Avatar>
                  <Typography variant="caption">{classroom.name}</Typography>
                </Box>
              }
              value={classroom?.id}
            />
          ))}
        </Tabs>
      </Paper>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 2, height: 400, overflowY: 'auto' }}>
            <Typography variant="h6" gutterBottom>
              Students
            </Typography>
            {renderStudentCounts()}
            {/* {renderCircles(getFilteredData('students'))} */}
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 2, height: 400, overflowY: 'auto' }}>
            <Typography variant="h6" gutterBottom>
              Teachers
            </Typography>
            {renderStaffCounts()}
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 2, height: 400, overflowY: 'auto' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6" gutterBottom>
                Attendance List
              </Typography>
              <DatePicker
                selected={new Date(selectedDate)}
                onChange={(date) => {
                  setSelectedDate(date.toISOString().split('T')[0]);
                  fetchTodayAttendance();
                }}
                customInput={
                  <IconButton>
                    <CalendarTodayIcon />
                  </IconButton>
                }
              />
            </Box>

            {renderAttendanceList()}
          </Paper>
        </Grid>
      </Grid>

      {/* Add Classroom Dialog */}
      <Dialog open={openAddClassroomDialog} onClose={handleCloseAddClassroomDialog}>
        <DialogTitle>Add New Classroom</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Classroom Name"
            type="text"
            fullWidth
            value={newClassroomName}
            onChange={(e) => setNewClassroomName(e.target.value)}
            required
          />
          <TextField
            margin="dense"
            label="Capacity (optional)"
            type="number"
            fullWidth
            value={newClassroomCapacity}
            onChange={(e) => setNewClassroomCapacity(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddClassroomDialog}>Cancel</Button>
          <Button onClick={handleAddClassroom} variant="contained" color="primary">
            Add Classroom
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Staff Dialog */}
      <Dialog open={openAddStaffDialog} onClose={handleCloseAddStaffDialog}>
        <DialogTitle>Add New Staff</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="First Name"
            type="text"
            fullWidth
            value={newStaffFirstName}
            onChange={(e) => setNewStaffFirstName(e.target.value)}
            required
          />
          <TextField
            margin="dense"
            label="Last Name"
            type="text"
            fullWidth
            value={newStaffLastName}
            onChange={(e) => setNewStaffLastName(e.target.value)}
            required
          />
          <TextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            value={newStaffEmail}
            onChange={(e) => setNewStaffEmail(e.target.value)}
            required
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Role</InputLabel>
            <Select value={newStaffRole} label="Role" onChange={(e) => setNewStaffRole(e.target.value)} required>
              <MenuItem value="Teacher">Teacher</MenuItem>
              <MenuItem value="Assistant">Assistant</MenuItem>
              <MenuItem value="Administrator">Administrator</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox checked={newStaffHasAllAccess} onChange={(e) => setNewStaffHasAllAccess(e.target.checked)} />
            }
            label="Access to all classrooms"
          />
          {!newStaffHasAllAccess && (
            <Button onClick={handleOpenClassroomSelect} sx={{ mt: 1 }}>
              Select Assigned Classrooms
            </Button>
          )}
          {newStaffClassrooms.length > 0 && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              Selected Classrooms: {newStaffClassrooms?.length}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddStaffDialog}>Cancel</Button>
          <Button onClick={handleAddStaff} variant="contained" color="primary">
            Add Staff
          </Button>
        </DialogActions>
      </Dialog>

      {/* Classroom Selection Dialog */}
      <Dialog open={openClassroomSelect} onClose={handleCloseClassroomSelect}>
        <DialogTitle>Select Assigned Classrooms</DialogTitle>
        <DialogContent>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {availableClassrooms?.map((classroom) => {
              const labelId = `checkbox-list-label-${classroom.id}`;

              return (
                <ListItem key={classroom?.id} dense button onClick={() => handleClassroomToggle(classroom.id)}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={newStaffClassrooms?.indexOf(classroom?.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={classroom?.name} />
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClassroomSelect} color="primary">
            Done
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Child Dialog */}
      <Dialog open={openAddChildDialog} onClose={handleCloseAddChildDialog}>
        <DialogTitle>Add New Child</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Child First Name"
            type="text"
            fullWidth
            value={newChildFirstName}
            onChange={(e) => setNewChildFirstName(e.target.value)}
            required
          />
          <TextField
            margin="dense"
            label="Child Last Name"
            type="text"
            fullWidth
            value={newChildLastName}
            onChange={(e) => setNewChildLastName(e.target.value)}
            required
          />
          <TextField
            margin="dense"
            label="Date of Birth"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={newChildDateOfBirth}
            onChange={(e) => setNewChildDateOfBirth(e.target.value)}
            required
          />
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Search Existing Parent</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', mb: 2 }}>
                <TextField
                  fullWidth
                  label="Search Existing Parent"
                  value={parentSearch}
                  onChange={handleParentSearch}
                  sx={{ mr: 1 }}
                />
                {isSearching && <CircularProgress size={24} />}
              </Box>
              {searchError && (
                <Typography color="error" sx={{ mb: 2 }}>
                  {searchError}
                </Typography>
              )}
              {searchResults.length > 0 && (
                <List>
                  {searchResults.map((parent) => (
                    <ListItem key={parent?.id} button onClick={() => handleSelectParent(parent)}>
                      <ListItemText primary={`${parent?.firstName} ${parent?.lastName}`} secondary={parent?.phone} />
                    </ListItem>
                  ))}
                </List>
              )}
              {searchResults?.length === 0 && parentSearch?.length >= 3 && !isSearching && (
                <Typography>No parents found. You can add a new parent below.</Typography>
              )}
              {selectedParent && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mt: 2,
                  }}
                >
                  <Typography variant="body1">
                    Selected Parent: {selectedParent?.firstName} {selectedParent?.lastName}
                  </Typography>
                  <Button onClick={handleRemoveSelectedParent} color="secondary">
                    Remove
                  </Button>
                </Box>
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Add New Parent</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                fullWidth
                label="Parent First Name"
                value={newParentFirstName}
                onChange={(e) => setNewParentFirstName(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Parent Last Name"
                value={newParentLastName}
                onChange={(e) => setNewParentLastName(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Parent Phone"
                value={newParentPhone}
                onChange={(e) => setNewParentPhone(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Parent Email"
                value={newParentEmail}
                onChange={(e) => setNewParentEmail(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Parent Address"
                value={newParentAddress}
                onChange={(e) => setNewParentAddress(e.target.value)}
                sx={{ mb: 2 }}
              />
              <Button onClick={handleAddParent} variant="contained" color="secondary" sx={{ mb: 2 }}>
                Add Parent
              </Button>
            </AccordionDetails>
          </Accordion>
          <Button onClick={handleOpenChildClassroomSelect} sx={{ mt: 1 }}>
            Select Classroom
          </Button>
          {newChildClassroom && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              Selected Classroom: {availableClassrooms.find((c) => c.id === newChildClassroom)?.name}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddChildDialog}>Cancel</Button>
          <Button onClick={handleAddChild} variant="contained" color="primary">
            Add Child
          </Button>
        </DialogActions>
      </Dialog>

      {/* Child Classroom Selection Dialog */}
      <Dialog open={openChildClassroomSelect} onClose={handleCloseChildClassroomSelect}>
        <DialogTitle>Select Classroom</DialogTitle>
        <DialogContent>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {availableClassrooms.map((classroom) => {
              const labelId = `checkbox-list-label-${classroom.id}`;

              return (
                <ListItem key={classroom.id} dense button onClick={() => handleChildClassroomToggle(classroom.id)}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={newChildClassroom === classroom.id}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={classroom.name} />
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseChildClassroomSelect} color="primary">
            Done
          </Button>
        </DialogActions>
      </Dialog>

      {/* Attendance Dialog */}
      <Dialog open={openAttendanceDialog} onClose={() => setOpenAttendanceDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>
          {attendanceType === 'checked_in'
            ? 'Check In'
            : attendanceType === 'checked_out'
            ? 'Check Out'
            : 'Not Attending'}
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Search Students"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ mb: 2 }}
          />
          {renderCircles(filteredStudents)}
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Pagination
              count={Math.ceil(filteredStudents.length / studentsPerPage)}
              page={page}
              onChange={handlePageChange}
            />
          </Box>
          <TextField
            fullWidth
            label="Notes"
            value={attendanceNotes}
            onChange={(e) => setAttendanceNotes(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAttendanceDialog(false)}>Cancel</Button>
          <Button onClick={handleAttendanceSubmit} variant="contained" color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Attendance;
